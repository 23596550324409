import React from 'react';
import '../App.css';
import Mint from './Mint-test-owner';

function Home() {
    return (
        <>
            <Mint />
        </>
    );
}
export default Home;
