import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './presaleMintingBAYC.css';
import api from '../api/redeemable_nuggets';
import { useHistory } from 'react-router-dom';

import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";

import img1 from '../assets/ethwalkers-wordmark.png';



function PrivateMinting() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [ape_zen_wallet, set_ape_zen_wallet] = useState()


    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)


    const [saleStarted, setSaleStarted] = useState(false)

    const [apePreSaleStarted, setApePreSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//


    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [nuggetCount, setNuggetCount] = useState([]);

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggets, setNuggets] = useState([]);

    const history = useHistory();
    const realnow = Date.now();
    const now = realnow/1000;

    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)
        console.log(exists)
        return exists;
    }

    useEffect(() => {
        const fetchRedeemable_nuggets = async () => {
            try {
                const response = await api.get('/redeemable_nuggets');
                setRedeemable_nuggets(response.data);



            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }

            }
        }




        fetchRedeemable_nuggets();
    }, []);

    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);



//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    function b(idToSearch) {
        return checkedNuggets.filter(item => {
            return item.id === idToSearch
        })
    };

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)

        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }


        const saleTime = await walkerContract.methods.presaleApeStart().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setApePreSaleStarted(true)
        }



        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)


    }

    async function mintDuck() {
        if (walkerContract) {

            const price = Number(getPrice)  * how_many_ducks




            const gasAmount = await walkerContract.methods.preSaleApesZenMintETHWalkers(how_many_ducks, ape_zen_wallet).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .preSaleApesZenMintETHWalkers(how_many_ducks, ape_zen_wallet)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })


        } else {
            console.log("Wallet not connected")
        }

    };
    async function verifyDuckOwner(tokenNumber) {
        if (nuggetContract) {
            const owner = await nuggetContract.methods.ownerOf(tokenNumber).call({from: walletAddress})
            console.log(owner)
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            if (owner.toLowerCase() === checker) {
                setOwner(true)
                console.log("you own this")
            } else {
                setOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            console.log(nuggetsOfOwner)
            const fetchRedeemable_nuggets = async () => {
                try {
                    const response = await api.get('/redeemable_nuggets');
                    setRedeemable_nuggets(response.data);
                    console.log(redeemable_nuggets);
                    response.data.forEach((item) =>{
                        console.log(item.id)
                    })

                    setCheckedNuggets(checkedNuggets => [])
                    setRedeemableState(redeemableState => [])
                    let k = 0;
                    for (let i = 0; i < nuggetsOfOwner.length; i++) {
                        response.data.forEach((item) =>{
                            if (item.id == nuggetsOfOwner[i]) {
                                console.log(nuggetsOfOwner[i])

                                setRedeemableState(redeemableState => [ ...redeemableState, {
                                    select: false,
                                    id: nuggetsOfOwner[i],
                                    nugget: "Redeem Nerdy Nugget " + nuggetsOfOwner[i],
                                    number: nuggetsOfOwner[i]
                                }])
                                k++;
                                console.log(k)

                            } else {

                            }
                        })

                    }




                } catch (err) {
                    if (err.response) {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else {
                        console.log(`Error: ${err.message}`);
                    }

                }
            }
            fetchRedeemable_nuggets();
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            console.log(walletAddress)
            if (walletAddress === checker) {
                setOwner(true)
                console.log("you own nuggets")

            } else {
                setOwner(false)
                console.log("you do not own nuggets")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function deleteNuggets() {
        checkedNuggets.forEach((item) =>{
            handleDelete(item.number)
        })
        setCheckedNuggets(checkedNuggets => [])
        setRedeemableState(redeemableState => [])

    }
    async function testLoop() {
        setNuggetCount(nuggetCount=>[])
        checkedNuggets.forEach((item) =>{
            const id = (item.number);
            setNuggetCount(nuggetCount => [ ...nuggetCount, {
                id
            }])
        })
        console.log(nuggetCount)
    };
    const handleDelete = async (id) => {
        try {
            await api.delete(`/redeemable_nuggets/${id}`);
            const nuggetsList = redeemable_nuggets.filter(redeemable_nuggets => redeemable_nuggets.id !== id);
            setNuggets(nuggetsList);
            history.push('/privateMinting');
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }

    }
    async function checkTime() {
        const saleTime = await walkerContract.methods.presaleApeStart().call()
        setSaleTime(saleTime)
    };

    const handleClick3 = () => {
        //console.log('Hello there!');
        console.log(checkedNuggets)
        console.log(redeemableState)
        console.log(ape_zen_wallet)
        console.log(now)
        checkTime()
        console.log(saleTime)

    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        nuggetsOwned(walletAddress)
        console.log(redeemableState)
    };



    const handleClick = () => {
        //console.log('Hello there!');
        mintDuck()
    };
    const handleClick0 = () => {
        deleteNuggets()

    };
return (
    <div className='new-body4'>
        <div className='title-backer'>
            <div className='lil-guy'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--small'
                    onClick={() => {history.push('/Navigation')}}
                >
                BACK
                </Button>
            </div>
            <img className="type-pic1" src={img1} alt="not shown"/>

        </div>
        <div className='mama'></div>

        <div className='sub-body'>
            <div className='hero-container-Private'>


                <h2>BAYC, MAYC, and ZenAcademy PRE-SALE MINTING</h2>
                <span></span>
                <div id="mint" className="flex">

                <span className="flex">Mint</span>
                <input
                                    type="number"
                                    min="1"
                                    max="20"
                                    value={how_many_ducks}
                                    onChange={ e => set_how_many_ducks(e.target.value) }
                                    name=""
                                />

                <span className="flex">ETH Walker Humans! (Limit 20 per tx)</span>



                </div>
                <div id="mint" className="flex">
                <span className="flex">Enter the wallet address you want to mint to: </span>
                <input
                                    type="string"

                                    value={ape_zen_wallet}
                                    onChange={ e => set_ape_zen_wallet(e.target.value) }
                                    name=""
                                />
                </div>
                <span>(The wallet you mint to must contain one of the following NFTs:</span>
                <span> BAYC, MAYC, and ZenAcademy)</span>
                <div className='hero-btns'>
                {apePreSaleStarted && saleStarted ?
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    onClick={handleClick}
                >
                MINT {how_many_ducks} Humans {(getPrice * how_many_ducks) / (10 ** 18)} ETH + GAS to wallet: {ape_zen_wallet}
                </Button>
                : <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >SALE IS NOT ACTIVE OR NO WALLET IS CONNECTED
                </Button>
                }
                </div>
                <div className="hero-btns">
                {!signedIn ?
                <Button
                    onClick={signIn}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Connect Wallet with Metamask
                </Button>
                : <Button
                    onClick={signOut}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Wallet Connected: {walletAddress}
                </Button>
                }
                </div>

            </div>
        </div>
    </div>



);
}
export default PrivateMinting;
