import { useEffect, useState } from "react";

export const useAddress = (signer) => {
  const [address, setAddress] = useState(null);
  useEffect(() => {
    const getAddress = async () => {
      if (signer !== undefined) {
        const addr = await signer.getAddress().catch(() => {});
        setAddress(addr);
      }
    };
    getAddress();
  }, [signer]);

  return address;
};
