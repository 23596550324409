import React, { useState, useEffect } from 'react';
import './CharacterDisplayTwo.css';
import axios from 'axios';

import api from '../api/walker_images';
import api2 from '../api/walker_backstory';
import api3 from '../api/walker_by_location';

function CharacterDisplayTwo(props) {



    const returnedCharacters = [{
            "image": "https://gateway.pinata.cloud/ipfs/QmbFWvmQpxgvESLUPZBkynMH9DFvDWMB6TqNoXRFzYs8YN",
            "tokenId": "1459",
            "name": "ETH Walker #1459",
            "attributes": [
                {"trait_type": "Class", "value": "Human"},
                {"trait_type": "Background", "value": "The Yacht Club"},
                {"trait_type": "Sex", "value": "Male"},
                {"trait_type": "Skin Tone", "value": "Five"},
                {"trait_type": "Body", "value": "Normal Body"},
                {"trait_type": "Hair", "value": "Style P"},
                {"trait_type": "Facial Hair", "value": "Facial Hair Style H"},
                {"trait_type": "Hair Color", "value": "Color 10"},
                {"trait_type": "Head", "value": "Head 13"},
                {"trait_type": "Brows", "value": "Brows 5"},
                {"trait_type": "Eyes", "value": "Eyes 12"},
                {"trait_type": "Pupils", "value": "Pupil 4"},
                {"trait_type": "Cheeks", "value": "Cheeks 5"},
                {"trait_type": "Mouth", "value": "Mouth 2"},
                {"trait_type": "Jaw", "value": "Jaw 14"},
                {"trait_type": "Nose", "value": "Nose 11"},
                {"trait_type": "Ears", "value": "Ear 4"},
                {"trait_type": "Age", "value": "Middle Age"},
                {"trait_type": "Outfit", "value": "Tactleneck 2"},
                {"trait_type": "Glasses", "value": "None"},
                {"trait_type": "Hat", "value": "None"},
                {"trait_type": "Leader", "value": "None"},
                {"trait_type": "Weapons", "value": "None"}],
            "combat": "none",
            "attack": "none"
        }];
    const [sendingCharacters, setSendingCharacters] = useState(returnedCharacters);
    const [searchedWalker, setSearchedWalker] = useState(props.item);
    const [currentImage, setCurrentImage] = useState([]);
    const [currentText, setCurrentText] = useState([]);
    const [currentLocation, setCurrentLocation] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [dataHere, setDataHere] = useState(false)
    const [leaderState, setLeaderState] = useState(null)

    // useEffect(() => {
    //     if (props.item===28) {
    //         setLeaderState(28)
    //     }else if (props.item===97) {
    //         setLeaderState(97)
    //     }else if (props.item===216) {
    //         setLeaderState(216)
    //     }else if (props.item===474) {
    //         setLeaderState(474)
    //     }else if (props.item===636) {
    //         setLeaderState(636)
    //     }else if (props.item===699) {
    //         setLeaderState(699)
    //     }else if (props.item===733) {
    //         setLeaderState(733)
    //     }else if (props.item===762) {
    //         setLeaderState(762)
    //     }else if (props.item===950) {
    //         setLeaderState(950)
    //     }else if (props.item===954) {
    //         setLeaderState(954)
    //     }else if (props.item===965) {
    //         setLeaderState(965)
    //     }else if (props.item===970) {
    //         setLeaderState(970)
    //     }else if (props.item===992) {
    //         setLeaderState(992)
    //     }else if (props.item===1063) {
    //         setLeaderState(1063)
    //     }else if (props.item===1096) {
    //         setLeaderState(1096)
    //     }else if (props.item===1107) {
    //         setLeaderState(1107)
    //     }else if (props.item===1181) {
    //         setLeaderState(1181)
    //     }else if (props.item===1215) {
    //         setLeaderState(1215)
    //     }else if (props.item===1279) {
    //         setLeaderState(1279)
    //     }else if (props.item===1306) {
    //         setLeaderState(1306)
    //     }else if (props.item===1316) {
    //         setLeaderState(1316)
    //     }else if (props.item===1338) {
    //         setLeaderState(1338)
    //     }else if (props.item===1342) {
    //         setLeaderState(1342)
    //     }else if (props.item===1403) {
    //         setLeaderState(1403)
    //     }else if (props.item===1713) {
    //         setLeaderState(1713)
    //     }else if (props.item===1724) {
    //         setLeaderState(1724)
    //     }else if (props.item===1881) {
    //         setLeaderState(1881)
    //     }else if (props.item===1920) {
    //         setLeaderState(1920)
    //     }else if (props.item===1957) {
    //         setLeaderState(1957)
    //     }else if (props.item===2461) {
    //         setLeaderState(2461)
    //     }else if (props.item===2555) {
    //         setLeaderState(2555)
    //     }else if (props.item===3037) {
    //         setLeaderState(3037)
    //     }else if (props.item===3380) {
    //         setLeaderState(3380)
    //     }else if (props.item===3514) {
    //         setLeaderState(3514)
    //     }else if (props.item===3689) {
    //         setLeaderState(3689)
    //     }else if (props.item===4067) {
    //         setLeaderState(4067)
    //     }else if (props.item===4259) {
    //         setLeaderState(4259)
    //     }else if (props.item===4405) {
    //         setLeaderState(4405)
    //     }else if (props.item===4577) {
    //         setLeaderState(4577)
    //     }else if (props.item===5459) {
    //         setLeaderState(5459)
    //     }
    //
    // },[]);






    useEffect(() => {
        let isUnmount = false;
        if(!isUnmount){
        const fetchCount = async () => {
            try {

                const response = await api.get('/'+searchedWalker);
                setCurrentImage(response.data.attributes.image_uri);

            } catch (err) {

            }
        }
        fetchCount();
        const fetchBackstory = async () => {
            try {

                const response = await api2.get('/'+searchedWalker);
                setCurrentText(response.data);

            } catch (err) {

            }
        }
        fetchBackstory();
    }
    return () => {
        isUnmount = true;
        console.log('cleanup')
    };
    }, []);





    return (

        <>

                <div className='character-display-location'>






                    <div className='gallery-container'>



                        <img className="characterPic57" src={currentImage}/>
                        {leaderState == null?
                        <>
                            <h2>(#{props.item}){currentText.name}</h2>
                            <div className='holder'>
                            <p>{currentText.backstory}</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 28?
                        <>
                            <h2>(#{props.item}) Trashie Quigley</h2>
                            <div className='holder'>
                            <p>Known throughout Portstown, but not understood, Trashie is the de facto leader of The Hills community. A self-labeled naturalist, Trashie lives off the land and spends her day communing with the forest. She is the sole point of connection between the people of The Hills and the mysterious "Isaac", their unseen spiritual guide.  While most members of the Hills rarely leave the compound, Trashie is commonly seen strolling the streets of Portstown and is the source of many whispers and side glances. On more than one occasion, her ventures have ended in the back of a police car, after verbal confrontations with the townsfolk or tripping out on psychedelics.  Despite her eccentric exterior, Trashie is extremely quick-witted and her loyalty to the people of the Hills knows no bounds. She is viewed as a mother-figure by most, and appears regularly in Mayor Givens’ office to advocate policy on behalf of her community. Lately, she has been spending more and more time in the woods. While none of The Hills members are quite sure what is going on, she apparently told Marilyn Breslin, in confidence, that she has been having "visions".</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 97?
                        <>
                            <h2>(#{props.item}) Rock Breslin</h2>
                            <div className='holder'>
                            <p>Rock has spent his entire life in Portstown. Growing up as a member of the Hills commune, he felt connected to his community but an outcast at school. Things changed in the 10th grade when beauty queen Betsy Dee was assigned as his science lab partner. The unlikely pair found "chemistry" in more ways that one. As Betsy pulled him up the social ladder, Rock felt pressure to break away from his people and their backwards way of living. He eventually left the commune, and earned room and board doing handiwork at the Buckner Estate. After being named King and Queen of the prom, Rock planned to propose to Betsy. The moment never came, as Betsy was awarded a full-ride to the University of Miami.  After a summer that passed too quickly, Betsy left an aimless Rock behind in Portstown. Her calls and letters became fewer and farther apart, until they eventually stopped. Rock returned to the Hills, eventually raising a daughter, Marilyn, as a single father. When Betsy returned to Portstown, now a Conway, something changed in Rock. Determined to shun the life of poverty that contrasted with the Conways, he took to crime, peddling drugs out of the Brain Bucket. Eventually, caught and arrested, he was sentenced to twenty years in the Belle River Penitentiary. On the second day of his incarceration, seemingly out of nowhere, he was offered a place in Juan O’de Dead’s gang. He quickly became O’de Dead’s right-hand man, a role he still holds today.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 216?
                        <>
                            <h2>(#{props.item}) Johnny O'Dea</h2>
                            <div className='holder'>
                            <p>Johnny is a trust fund playboy with too much time and not enough brains to know how to spend it well. He is the current patriarch of the O’Deas, Portstown’s most prestigious family. From the 1960’s until the mid-2000’s the O’Dea name was the definition of high-class in the small town. The family was intimately involved with all community events and occupied the town’s opulent mansion.  Tragedy struck the family in 2005 when Johnny and Barbra’s father, Rupert, drowned in the floods of Hurricane Katrina. From that moment on, Johnny has tried (and failed) to fill the shoes of his beloved father. He sold the family business and started a new venture focused on life-extension pseudoscience, which has failed to yield any meaningful breakthroughs. His status in the town took a further hit with the arrival of Joseph Conway, who quickly inherited the mantle of Portstown’s most influential person. Knowing he does not possess the resources, power, or intellect to compete with Joseph, Johnny is insanely jealous of Conway.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 474?
                        <>
                            <h2>(#{props.item}) Just Givens</h2>
                            <div className='holder'>
                            <p>Mayor Givens holds the most important role in the town (on paper, that is). Nicknamed "The Puppet of Portstown", Givens has taken up residence in the back pocket of Joseph Conway, the town’s wealthiest member. There isn’t a decision Givens makes that isn’t directly under Conway’s influence.  Early on in his career, Givens was a public defender in Baton Rouge and was widely known as one of the most promising young lawyers in the Louisiana justice system. When the Belle River Penitentiary was upgraded to supermax status, Givens moved to Portstown with the hope of advocating for those who were wrongfully incarcerated. After making several successful appeals, he was approached by members of the town council about a potential run for Mayor. The former Mayor had taken an environmentally-focused position, leading to some controversial decisions such as the shuttering of the old coal mine and regulations prohibiting any development in or along the waterway. Unseating the Mayor would be a tall task, as he possessed near unanimous backing from The Hills community. This made gaining the majority vote a near impossibility.  However, impossible is nothing for a man with the means of Joseph Conway. The small taste of power had whet Givens’ appetite, and after agreeing to run, his small-town political campaign became flushed with cash that would rival a Senate race. Smear campaigns, backdoor deals, and bribes led to the eventual rise of Mayor Just Givens. After being sworn in, he quickly learned that the campaign contributions had created a debt that could only be repaid with unquestioned loyalty to his billionaire benefactor. In the years that have followed, Portstown has slowly spiraled into disarray, led by a man controlled by the whims of Joseph Conway.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 636?
                        <>
                            <h2>(#{props.item}) Marilyn Breslin</h2>
                            <div className='holder'>
                            <p>Marilyn wasn’t given the easiest lot in life. Her mother abandoned her days after she was born, leaving her to be raised by a single father, Rock Breslin. She grew up in a small hut in The Hills. By its nature, the commune doesn’t lend itself well to family life and children, so she grew up with few friends, surrounded by outsiders, oddballs, and conspiracy theorists. While she was still young, her father was arrested on drug charges and sent to Belle River, where he currently resides.  With no one to care for her, she was taken in by community leader, Trashie Quigley. While Trashie made sure that Marilyn was safe and fed, she lacked any real maternal instincts. Marilyn has been mostly left to fend for herself, although she has developed ties to some members of the commune, such as Allan Tarman. She is currently working at Nerdy Nuggets and saving up to be able to rent a place and start a new life somewhere else.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 699?
                        <>
                            <h2>(#{props.item}) Joseph Conway</h2>
                            <div className='holder'>
                            <p>In his early twenties, Joseph built the Miami biotech startup, Revenant. He quickly took the company public and became one of the world’s youngest billionaires. During a guest lecture at the Univeristy of Miami, he became enamored with student attendee, Betsy Dee. The two quickly hit it off, had a whirlwind engagement, and were married.  Five years into their marriage, Joseph made the decision to shun the Miami highlife and moved to Portstown. While outsiders view him as a recluse, Joseph is intimately involved in town politics, pulling the strings and using his fortune to gain influence.  His relationship with Betsy fractured after the move to Portstown and he became an overt philanderer. His latest fling is with Karey Z. Enos, an ER nurse at Portstown General.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 733?
                        <>
                            <h2>(#{props.item}) Shaun Edgar</h2>
                            <div className='holder'>
                            <p>Shaun Edgar runs an eclectic store in Downtown Portstown. Although the sign reads "antiques", everyone knows it’s essentially a pawn shop. The store is very much a reflection of its owner, who outwardly appears to be a sophisticated Brit with an eye for the finer things in life, but behind closed doors is a football hooligan who still lives with his mother.  Shaun has a special knack for finding rare items and placing them in the hands of their wealthy new owners. This has made him especially useful to Barbra O’Dea who has taken a keen interest in historical artifacts in recent years. Barbra finds Shaun’s polished demeanor quite appealing and has taken quite the liking to the antiques dealer. While Shaun finds Barbra a bit eccentric and overwhelming, he knows a good opportunity when he sees one and has aligned his interests with the heiress’s pocketbook.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 762?
                        <>
                            <h2>(#{props.item}) Dr. Jane Festor</h2>
                            <div className='holder'>
                            <p>Dr. Jane Festor earned a double PhD in molecular biology and human genetics from Harvard. She quickly became a top recruit in the pharmaceutical world, specializing in the development of gene therapy. How this brilliant scientist went from the top of her lucrative field to teaching entry-level biology at a no-name school is something that has never been explained. Yet here she is in Portstown, teaching binomial nomenclature and basic cell division to meatheads like Freddy Hansome.  When the star quarterback started flunking he class, earning grades lower than a score you’d get from randomly guessing on a multiple choice test, Dr. Festor had little tolerance for his stupidity. She refused to inflate his scores to keep him eligible for the team, and when assigned study sessions with his lab partner failed to help, she brought him to her office for a stern scolding. During their conversation, the scientist’s disdain turned to a burning desire. While it’s not clear who made the salacious offer, the two have been meeting for private "tutoring" sessions with a notable improvement in Freddy’s grades.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 950?
                        <>
                            <h2>(#{props.item}) Ben Jones</h2>
                            <div className='holder'>
                            <p>Ben Jones always had an itch for adventure and dreamed of being a real-life Indiana Jones. All his degree in Egyptian Civilization got him was a dead-end job curating a dusty museum in Philadelphia. With barely any budget to travel and his rock band stuck playing dive bars in Delaware, his aspirations of living life on the wild side were failing to pan out. Then one day, out of the blue, he received invitation to apply for the newly-established Chair of Ancient Studies at Portstown University in Louisiana. The distinguished position was being funded by the O’Dea Family Trust and offered an escape from his current rut.  After being flown in for a series of interviews with his potential benefactors, he was quickly offered the job. Now he spends his days teaching to a small group of disinterested students, while his evenings are monopolized performing obscure research for Barbra O’Dea. In between meetings with archaeologists and foreign visitors selling artifacts, he’s managed to find time to date Dr. Lisa Punnett. While things are casual and he’s never been one for settling down, he uniquely drawn to the beautiful and wounded doctor.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 954?
                        <>
                            <h2>(#{props.item}) Franny Praker</h2>
                            <div className='holder'>
                            <p>In an earlier life, Franny Praker was an anchor on the highest-rated Miami evening news show. Her prominent position thrust her into the stratosphere of the Miami social scene. She found herself in the front row of sporting events, rubbing elbows with celebrities and the wealthy elite, like Joseph and Betsy Conway.  Father Time isn’t kind to those in front of the camera, and as Franny entered her late 30’s, she found herself replaced by a younger, perkier version of herself. With her career and social life in Miami evaporating, Franny turned to her best friend, Betsy, who had relocated to Portstown years earlier. She took a job at WPTR, anchoring the news alongside Ron Vermillion. Franny’s new, simpler life seemed to be off to a good start until she was set up on a date with Mayor Givens at the urging of Joseph Conway. After a few nights out and some unwanted advances, Franny politely let Givens know that she was not interested. A few days later "budget cuts" at WPTR necessitated that the station drop down to a single news anchor, and no one was unseating Ron Vermillion. Franny’s town-funded position was reassigned to the town clerk desk, directly under the supervision of Mayor Givens. Franny contemplated leaving the job, but there are only so many doors that a communications degree will open in Portstown.  Things haven’t all gone poorly for Franny, though. She recently began dating WPTR reporter, Steven Mandrews Jr., and the relationship is starting to get serious. Franny gets a small sense of pleasure watching Mayor Givens turn green with jealousy when Steven picks her up from work each day.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 965?
                        <>
                            <h2>(#{props.item}) Harry Cooper</h2>
                            <div className='holder'>
                            <p>A life-long native of Portstown, Harry has led a quiet and uneventful life. He struggled with dyslexia in elementary school and was labeled as "slow". In highschool, his awkward demeanor made him an outsider with few friends. He held a giant crush on Betsy Conway, but was too scared to make an advance, which would have almost certainly been rebutted.  After dropping out of school his senior year, he took a job tending the boats at the yacht club. He moved out of his mother’s house and now lives alone. Since Betsy returned to town, he steals a glance every now and then when she’s at the club, imagining what might have been if he was another person in another life.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 970?
                        <>
                            <h2>(#{props.item}) Joe Johnson</h2>
                            <div className='holder'>
                            <p>To those passing by, there doesn’t appear to be anything special about Joe Johnson. He works a boring job at the phone kiosk at the mall. He lives with his mother who runs the small grocery store Downtown. While he has dreams of escaping to the big city, he seems destined to spend his life in this small town. Heck, even his name is as generic as they come. However, if you wound the clock back fifteen years, you’d learn that this seemingly unremarkable man was once known as the "miracle child" of Portstown.  On a blistering hot July day, Joe went missing during a trip to the town swimming pool. After his mother, Henrietta, frantically alerted the lifeguards, the crowded pool was cleared, revealing the child at the bottom of the deep end. No one knows exactly how long Joe spent at the bottom of that pool, but witnesses say it was at least 20 minutes. After multiple failed rounds of CPR, his pulseless body was loaded onto an ambulance and driven to Portstown General. That evening, the mourners holding vigil at the St. Lazarus Church had their prayers interrupted by Henrietta Johnson, who burst through the doors of the church, with joyful cries that her son was alive.  For years, Joe was viewed by the townsfolk as something special. Over time, the memories of that day have faded and the miraculous child is now an ordinary man.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 992?
                        <>
                            <h2>(#{props.item}) Jessica Benson</h2>
                            <div className='holder'>
                            <p>After growing up in her father’s hardware store and walking the streets of Downtown each day there isn’t a face that’s not familiar to Jessica Benson. After graduating from Portstown U, she felt the call to protect and serve the people of the town she loves. Unfortunately, calling and capability do not always go hand in hand. Jessica’s time in the police academy was an unmitigated disaster. She failed her weapons training, skated by the exam with a razor thin margin, and would frequently break down crying. Psychological assessments found her to be indecisive and gullible. Nearly dismissed on several occasions, she persevered and graduated.  In the end, the only station desperate enough to employ her was in her hometown. In the first year on the job, she shed more tears than traffic tickets. When responding to incidents at the Brain Bucket, she’s met with laughter and disrespect. One day, she hopes to be worth of the badge, but it doesn’t seem like that will be anytime soon.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1063?
                        <>
                            <h2>(#{props.item}) Barbra O'Dea</h2>
                            <div className='holder'>
                            <p>Barbra is a former Hollywood horror movie actress with a flair for the dramatic. After her career ran its course, she returned to Portstown and lived with her family in the mansion. The clear favorite over her brother, Johnny, Barbra was inseparable from her father, Rupert. For a time, she was the town’s leading lady, attending every event and at the center of every photo op.  Following the death of her father after Hurricane Katrina, she went into a horrible depression and withdrew from community life. Years later, she re-emerged, but was a weathered shell of the warm, personable lady the town had grown to love. Rumors swirl that Barbra is obsessed with communicating with the dead and may have cryogenically frozen her father’s head. While she is no longer a town socialite, she occasionally hosts visitors from around the globe at the mansion and lately has been seen in the company of Ben Jones.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1096?
                        <>
                            <h2>(#{props.item}) Columbus Eisenberg</h2>
                            <div className='holder'>
                            <p>Columbus was once one of Portstown’s most promising youth. Raised by a church-attending single mother, both he and his younger half-brother, Robert were known for being polite, well-mannered boys. In his high-school years, Columbus became a stand-out sprinter and a star student and was offered a full scholarship to LSU.  Days before he left for college, his hopeful future took a dark turn. Found blood-soaked and holding the weapon over the body of a murder victim, Columbus was arrested, tried, convicted, and sentenced to life in prison. At the behest of his mother, he was allowed to serve his time at the local Belle River Penitentiary, where he has remained for ten years.  His pleasant demeanor made him a favorite with the guards, but also a target to the other inmates. After an altercation with Juan O’de Dead’s crew left one attacker permanently blind in both eyes, Columbus was moved to solitary confinement. He spends his days reading books and meditating in silence.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1107?
                        <>
                            <h2>(#{props.item}) Tom Washer</h2>
                            <div className='holder'>
                            <p>Handsome, athletic, charming, and the 2012 winner of the Yacht Club’s "Mr. Portstown" competition, Tom Washer seemed destined for great things. With his uncle being Chief of Police, everyone assumed that Tom would join the family business and become an officer. What they didn’t expect was the string of disgraces that would follow in the wake of Washer’s popularity.  First it was the partying that lead to a DUI. Next it was being found beaten to a pulp outside the Brain Bucket as recourse for unpaid gambing debts. The questionable life choices continued, excluding him from any shot at entering the police academy. The screw-ups continued, culminating in a trip to the emergency room six years ago for a massive seizure episode, presumed to be due to some kind of substance abuse.  After hitting that low, Tom made a concerted effort to clean up his life. After several years of trouble-free living, a lack of available officers left his uncle Pete desperate enough to pull a few strings to get him in a spot at the academy. Tom is now an excellent officer, clean cut, heroic, and a brilliant strategist. However, the shadows of his past and his uncle’s less-than-stellar reputation still hang over him.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1181?
                        <>
                            <h2>(#{props.item}) "Knives" Morgan</h2>
                            <div className='holder'>
                            <p>Glen Morgan grew up in the butcher shop and the church pew. On Saturdays he would trim meat in his father’s butcher shop and on Sundays he would watch his mother, Helena, prophesy on the St. Lazarus Church platform. He became quick friends with Columbus Eisenberg, and the two auspicious youths were a source of Portstown’s pride. Both were awarded scholarships to LSU, and Morgan dreamed of earning a business degree and turning his father’s butcher shop into a barbeque empire.  Those dreams were dashed when Columbus was convicted of second-degree murder. Although Morgan was nowhere near the scene of the crime, his guilt by association led to LSU withdrawing his scholarship. Morgan briefly enrolled in Portstown University, but fell into depression and flunked out his second semester. He eventually found a home among the Blades. His proficiency with knives earned him his nickname, and after a series of fights left his opponents thoroughly butchered, Morgan became the most feared member of the gang. He still wears a white suit to church every Sunday.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1215?
                        <>
                            <h2>(#{props.item}) Pete Washer</h2>
                            <div className='holder'>
                            <p>Pete Washer is the Portstown Chief of Police. He has spent his entire career protecting the town. Early on, the job was easy. The quiet town had almost no crime and few visitors from outside. Pete spent the time created by the boredom nursing a bottle of alcohol. Eventually, the years of drinking caught up to him. His propensity for violent outbursts led to the end of his marriage to Diana Washer (now Wright).  His alcoholism would have also cost him his job, if he weren’t adept at covering his tracks and burying bodies. Eventually, he was promoted to the position of Chief, more by default than being deserving. He eventually gained sobriety, and tried (and failed) to reunite with Diana.  While not the violent man he used to be, he still wrestles with his demons. Under his slipshod leadership, crime in Portstown has reached an all-time high. The Blades gang is running rampant and the waterfront has become a hotbed for drug trafficking. Despite the growing distrust of the community, Pete has maintained his position of power by kowtowing to the whims of Mayor Givens. Most of his best officers have quit out of apathy or disgust. He was eventually forced to hire his screw-up nephew Tom and the naive female rookie to keep the station staffed.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1279?
                        <>
                            <h2>(#{props.item}) Edith Rand</h2>
                            <div className='holder'>
                            <p>Edith has patchy fond memories of her early childhood. Rocking horses, pigtails, birthday parties, and two loving parents. They stand in abrupt contrast to the chaotic reality of her youth spent in the Miami foster care system. Hunger, abuse, and a lack of security defined her formative years as she bounced from caregiver to caregive, never spending more than two years in a single home.  Most children would have been rendered broken and hopeless, but as she reached adulthood, Edith used her experience as fuel to make the world better. After spending time overseas on humanitarian missions, Edith enlisted in the Army. She excelled at basic training, her years of hardship giving her the edge needed to endure.  Fresh off her promotion to private first class, Edith was approached by her superior regarding a secret tactical mission. With no details of what lay ahead, she found herself about a boat to Portstown, Louisiana, sitting across from a twitchy, strung-out Colonel.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1306?
                        <>
                            <h2>(#{props.item}) Betsy Conway</h2>
                            <div className='holder'>
                            <p>Betsy grew up in Portstown in a middle class family. She had always intended to stay in town and marry her high-school sweetheart, Rock Breslin after graduation. However, plans changed when she was offered a cheerleading scholarship to the University of Miami. She eventually caught the eye of billionaire entrepreneur Joseph Conway, whom she married.   Her life as a Miami socialite came to a crashing halt when Joseph inexplicably decided to relocate to his wife’s former hometown. Betsy resented the decision and their relationship grew cold. In addition to overtly disdaining Portstown and its citizens, she secretly hates Joseph, who she knows is unfaithful to her. After her parents died in a car accident, Betsy’s only close connection in town is her best friend, Franny Praker.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1316?
                        <>
                            <h2>(#{props.item}) Steven Mandrews Jr.</h2>
                            <div className='holder'>
                            <p>Steven Mandrews Jr. has never been able to escape the shadow of the father with whom he shares a name. In his early years, he always seemed to find himself in minor trouble at school. What would have otherwise been seen as normal boyhood mischief became a sully on the reputation of the esteemed Sergeant Mandrews.   As Steven grew, he opposed the pressure to begin a military career, and followed his own path.  He obtained a communications degree from Portstown University and landed a job as a news reporter for the local radio station, WPTR. Ironically, during a visit to see his son, Sergeant Mandrews hit it off with local celebrity Ron Vermillion and the two became fast friends. With his career in the Marines winding down, the Sergeant took an offer from Vermillion to be his wingman on the traffic and weather scene.  Now the second-rated Mandrews at WPTR, Steven Jr. has been working tirelessly to carve out his own niche. In addition to dating the lovely Franny Praker, Steven has spent his evenings investigating a bombshell story about narcotics smuggling out of the Brain Bucket. He is making his final edits before filing his edits to the WPTR newsdesk, the Portstown Police, and Mayor Givens.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1338?
                        <>
                            <h2>(#{props.item}) Robert Navelle</h2>
                            <div className='holder'>
                            <p>Funny, engaging, and undeniably cool, Robert Navelle is the kind of person that everyone wants to be friends with. Somehow, he ended up as besties with Chunk Philbin, who happens to be on the opposite end of the popularity spectrum. Since middle school, the two have been inseparable and now work the fryers at Nerdy Nuggets.  What drew Robert to Chunk may have been his friend’s quiet, thoughtful tendencies, which reminded him of his older half-brother, Columbus Eisenberg.  For a long time, Robert and Columbus were joined at the hip and did everything together. Robert was dreading the day that Columbus would leave and go off to college, but never imagined that something worse would happen. When Columbus was convicted of murder and sentenced to life in prison, Robert’s world was completely shattered. Robert is fully convinced of his brother’s innocence and has gone to visit his brother every weekend since he was incarcerated, without fail.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1342?
                        <>
                            <h2>(#{props.item}) Brenda Blausch</h2>
                            <div className='holder'>
                            <p>Brenda started out with a fairy tale childhood. She had two doting parents, both doctors, and lived in a big house with a white picket fence on the good side of town. Then when she turned nine "the incident" happened.  To this day, Brenda isn’t exactly sure of the details, but that’s what her father calls the tragic event that took her mother’s life. On that fateful day, she lost not one, but two parents. Her father was never the same in the wake of her mother’s death. He became a shell of himself, took to drinking, was suspended from his job at the hospital. To call him a deadbeat dad would be giving him too much credit. He wasn’t a father at all. Just a man who shared a roof with her and paid her bills.  Since that day, Brenda has largely been left to take care of herself. She graduated high school, barely. Although she inherited her parents’ smarts, she lacked the motivation to propel herself forward. She never bothered to apply for college, instead taking a job at the register at the sporting goods store at the mall. These days, she doesn’t see much reason to keep going and get out of bed. Although the guy working at the phone kiosk did catch her eye. They bumped into each other one day at the food court, and he said his name was Joe. Brenda thinks she’s caught him checking her out a few times when she walked by his counter.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1403?
                        <>
                            <h2>(#{props.item}) Henrietta Johnson</h2>
                            <div className='holder'>
                            <p>Henrietta is part of the glue that holds Portstown together. As the owner of the town’s only grocery store, every man, woman, and child has come to lean on her for sustenance. She’s a friend to all, and a mother figure to many. However, only one person in Portstown can actually call her "mom", her adopted son, Joe.  Married at a young age, Henrietta was unable to conceive. She spent countless nights at church, praying for a child that she would never bear. When it seemed like she would grow old without a child, she was given the opportunity to adopt three-year-old Joe and raised him with all the love she had to give.  As well-liked as she is, Henrietta is not without adversaries. For years, Mayor Givens has been angling to bring a big-box superstore to Portstown, adding competition that would almost certainly cripple her small business and force her out of town. Petitions and protests fell on deaf ears, and the process was only halted when Trashie Quigley produced zoning documents that revealed the store’s construction would violate a nature preserve. When they bumped into each other weeks later, Henrietta extended gratitude to Trashie. Taken aback, Trashie replied that she did it because "Joe needs to be here", and then strolled away muttering to herself.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1713?
                        <>
                            <h2>(#{props.item}) Dr. Milton Blausch</h2>
                            <div className='holder'>
                            <p>Dr. Milton Blausch was once the face of Portstown General Hospital. As the Chief of Surgery, he literally held the lives of then townsfolk in his hands. He was in line to become the hospital’s Medical Director when tragedy struck. After losing his wife and being left to raise their nine-year old daughter alone, he entered a spiral. He stopped operating and started drinking heavily. With his medical license hanging in the balance and youth and social services threatening his custody of Brenda, he made the necessary changes to preserve his crumbling life.  A brief stint in rehab was enough to keep him sober, but failed to ease the pain. He still refuses to operate, instead swinging night shifts in the ER, splinting broken bones and sewing up drunks after bar fights. He lives in the same house as his daughter, but the two barely speak. He’s strung-out, short-tempered, and anti-social, nothing like the shining hero he once was. Although he professes his innocence, he is currently under investigation regarding a large number of opioids that disappeared from the hospital pharmacy and may be getting funneled through Brain Bucket.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1724?
                        <>
                            <h2>(#{props.item}) Paul "Doc" Benson</h2>
                            <div className='holder'>
                            <p>Paul Benson has been a staple of the Portstown community for as long as anyone can remember. Since opening his hardware store Downtown over 40 years ago, he’s been the go-to person for any handy work needed in the small down. His penchant for diagnosing and fixing problems in everything from appliances to plumbing, and even computers, earned him his nickname, "Doc".  Not everyone in Portstown, however, has the same level of affection for the grandfatherly figure. The Blades have been extorting Benson for years, forcing him to make payments for "protection" to ensure that his store windows remain unbroken and his shelves unlooted. While he is strictly forbidden from reaching out to the police, Benson did mention his dilemma to Mayor Givens once, only to have his concerns fall on deaf ears.  With crime on the rise in the town he calls home, he’s extremely proud of his daughter Jessica, who has recently joined the Portstown Police. Although he’s secretly worried about her readiness for such an intense job, he hopes that his new connection to the law may finally free him from his arrangement with The Blades.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1881?
                        <>
                            <h2>(#{props.item}) Rudi Riccy</h2>
                            <div className='holder'>
                            <p>Growing up, there was nothing particularly special about Rudi. He was a typical middle-class Portstown youth, who was average in school and could hold his own on the football field. However, his stoic nature and good character caught the eye of his nextdoor neighbor, policeman Pete Washer. Pete became a mentor to Rudi and intended to groom him for a spot on the force. As Riccy reached his twenties, the two became extremely close, attending Portstown U football games together and frequenting neighborhood bars.  Rudi was eventually accepted into the Police Academy. However, that moment of pride for him and Pete was short-lived. On a late-night drunken stroll home from the bar, two were accosted by a group of thugs. As they fought the attackers off, Rudi managed to wrestle one assailant to the ground and Pete cuffed him. With the captive man writhing on the ground, Rudi watched in horror as Pete pulled out his gun and put a bullet in each of the attacker’s legs. In an affidavit, Pete claimed that the shots were fired in self-defense. Rudi remained silent, but dropped out of the Academy and cut all ties with Pete.  Washer rose in the ranks to Police Chief, while Riccy walked the opposite path, joining the Blades motorcycle gang, and gaining notoriety as a small-time criminal. The two crossed paths multiple times over the years, with Riccy in the back of a squad car or spending a night in the holding cell.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1920?
                        <>
                            <h2>(#{props.item}) Diana Wright</h2>
                            <div className='holder'>
                            <p>Diana grew up in England and when she boarded a plan to New Orleans forty years ago, she had no idea that she would never go back. While on her trip she met a handsome policeman named Pete Washer and fell in love. They eventually laid down roots in Portstown, with Pete joining the force and Diana opening up a small art studio Downtown. Eventually the kids came along.  At first, it seemed like the Washers were living the American Dream. However, as family life grew more busy, Diana had less time for her art and shut down the studio. Without an outlet to express herself, she felt like a piece of her was missing. She resented Pete’s long hours, and when he would come home drunk late at night, the two would end up in epic fights. One night, things escalated and got violent. Diana took the kids and left.  After the divorce was finalized, she thought about going back to her art, but she never fully had it in her to rebuild the studio.  She ended up taking a job at the department store in the mall, working for Lana Ellison. The two became close friends, and Diana is now managing her Mayoral campaign.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 1957?
                        <>
                            <h2>(#{props.item}) Karey Z. Enos</h2>
                            <div className='holder'>
                            <p>Karey Enos has lived in Portstown for three years. After transferring from her prior nursing job at Tulane Medical Center, she took a bedside position in the Portstown General ICU. Portstown is not a hotbed of activity for an attractive woman in her 20’s, but Karey has managed to carve out a life for herself. She befriended Dr. Lisa Punnett and the two socialize when Karey can pry her away from work. After striking out on the dating scene, fate brought love into her life.  Two years ago, the yacht carrying Joseph Conway capsized during a routine outing. While the billionaire was uninjured, he was held in the ICU overnight for observation. With little else to occupy his time, patient Conway spent the evening hitting on his young nurse who was flattered by the affection. After his discharge, Karey found herself the recipient of lavish bouquets of flowers and an invitation to a private evening on Joseph’s boat. The two have been seeing each other regularly ever since. While Karey isn’t quite sure she’s the only woman in his life, the thrill of Joseph’s romantic gestures certainly provide more satisfaction than advances from the low-lifes hanging out at the Brain Bucket.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 2461?
                        <>
                            <h2>(#{props.item}) James Maxwell</h2>
                            <div className='holder'>
                            <p>Growing up as the son of a two-star Army General, James Maxwell used the strict rules and discipline to reach his full potential. A straight-A student, phenomenal athlete, and stalwart of his community, James was accepted to West Point and graduated at the top of his class. He served a tour in Bosnia and two in Afghanistan, reaching the rank of Lieutenant Colonel.  Days before his second tour ended, Maxwell’s convoy came under fire and he was captured by the Taliban. Presumed dead, he remained in captivity for three years until he was found during a military raid on his sleeper cell. Once home, James sought to return to the only life he had ever known and resume active duty. The years of torture, however, had taken their toll and he was declared mentally unfit for combat due to poor impulse control, lack of basic human empathy, and rage issues.  Maxwell became a recluse and filled the void in his life with hand-to-hand combat training. 48 hours ago, he was approached by a high-ranking military official and offered a special assignment, which he accepted. After boarding a helicopter to New Orleans, he and Private Rand arrived by boat to Portstown.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 2555?
                        <>
                            <h2>(#{props.item}) Freddy Hansome</h2>
                            <div className='holder'>
                            <p>Freddy Hansome was never supposed to end up in Portstown. A five-star football recruit, Freddy had earned a scholarship to LSU and was on track to compete for the starting quarterback position. His fate took a painful turn when he tore the bicep in his throwing arm late in his senior season. With LSU no longer an option, Freddy decided to rehab his arm and potential career at Division II Portstown University.  The school never had a player of Freddy’s talent and he took the school to unprecedented heights. After finally being cleared to play, mid-season, Hansome went undefeated and led the Crawdaddies to a conference championship. With a trophy in one hand and his gorgeous girlfriend, Tina holding the other, it seemed like Freddy was living the college dream. His grades, however, told another story.  While most professors were willing to pass him through with little effort required, his biology lecturer, Dr. Jane Festor held him to a much higher standard. His flunking grade nearly kept him out of the playoffs, until some extra "tutoring" sessions pulled up his grade. Despite having many easier professor options, Freddy signed up for Biology II with Dr. Festor.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 3037?
                        <>
                            <h2>(#{props.item}) Juan O'de Dead</h2>
                            <div className='holder'>
                            <p>No one knows exactly where Juan O’de Dead comes from, but the trail of bodies that lie in his wake stretches all the way to Juarez, Mexico. Fifteen years ago, the "Demonio Blanco" terrorized the city with a massive string of seemingly senseless killings. With the body count rising into the 60’s and the pace of the murders accelerating, the Mexican government staged an all-out manhunt for the serial killer.  Ultimately, it was pursuit by the drug cartel that pushed Juan across the border. No longer the most feared operation in Juarez, the cartel placed a $20 million bounty on his head. Shortly thereafter, bodies began turning up across Texas from El Paso to Galveston. Male, female, old, and young – there was no pattern to the brutal killings and seemingly no motive. America’s 4th most wanted man was on pace to become one of the most prolific mass murderers in U.S. history before a brazen act lead to his capture.  In stark contrast to his usual shadowy murders, O’de Dead strangled a silver-haired businessman in broad view during a charity dinner in Baton Rouge. After his conviction and twenty-three life sentences, he was transferred to Belle River. Within days, eight leaders of the prison’s gangs were found dead in the yard. O’de Dead has sat on the penitentiary’s throne ever since.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 3380?
                        <>
                            <h2>(#{props.item}) Dr. Lisa Punnett</h2>
                            <div className='holder'>
                            <p>There’s not a smarter, harder worker in all of Portstown than Dr. Lisa Punnett. Graduate of Stanford, and trained at Johns Hopkins, Dr. Punnett was at the top of her field and a noted academic. Then, the woman who loved her career, fell head over heels for a man from Portstown, Louisiana.  She traded her prestige for a position at Portstown General and a chance at true happiness. For a while, she found it, and then the cancer struck. Things went too fast. There was no wedding, no children – only a pile of medical bills and a hole in her heart. Although she had every reason to leave Portstown, she chose to stay. Perhaps the town was the only way to retain a piece of him.  Now, she buries herself in her work, routinely putting in 14-16 hour shifts. A few months ago, Karey Enos dragged her to a singles event where she met Professor Ben Jones. The two have been seeing each other casually, but the intriguing history professor is no replacement for the love of her life.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 3514?
                        <>
                            <h2>(#{props.item}) Tina Randolph</h2>
                            <div className='holder'>
                            <p>Tina Randolph is the stereotypical "girl next door", perky, personable, and undeniably pretty. Her affection has been the source of fierce competition among the young men of Portstown. In the midst of a revolving door of potential suitors, the one constant during her high school years was her good friend Chunk Philbin. As the one guy who never seemed to put on move or have ulterior motives, Chunk earned Tina’s trust and and became her go-to for male companionship.  Their bond was solidified in 10th grade when Chunk’s mother passed away. In the weeks following, Tina would spend the evenings at Chunk’s apartment, talking through his feelings and holding him while he sobbed. When an opportunity to attend a big name school failed to materialize, Tina’s one consolation was that she would have Chunk by her side as they entered Portstown U. However, as the fall semester began, the dynamic of their friendship changed. Chunk appeared to be more withdrawn and disinterested in Tina’s blossoming social circles. He seemed particularly antagonized by her new boyfriend, Freddy, which pushed the two further apart. Now, the two friends barely speak, leaving a void that somehow can’t be filled by her popularity and Freddy’s impressive physical frame.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 3689?
                        <>
                            <h2>(#{props.item}) Chunk Philbin</h2>
                            <div className='holder'>
                            <p>Charles "Chunk" Philbin is an unassuming college student paying his way through Portstown U behind the fryer at Nerdy Nuggets. Philbin grew up in Portstown and his middling grades left him with only one option when it came to higher education. Not that his obligations would have allowed Chunk to leave town anyway. After his mother passed away from an autoimmune condition, Chunk was raised by his grandmother, with whom he shares an apartment on the poor side of town.  Philbin is currently a film major and daydreams of directing a romantic comedy in which he wins the heart of his leading lady Tina Randolph. Since their days on the school playground, Chunk has been completely enamored with Tina. However, he has never been able to escape the "friend zone" and watched from the sidelines as other, more impressive gentlemen accompanied her to the prom and weekend dates at the mall. As the two entered Portstown University, Chunk planned on working up the courage to make his move. However, by midway through the first day of freshman orientation, Tina’s affection had already been scooped up by football standout, Freddy Hansom.  As their social circles separated over the first semester, Chunk and Tina’s relationship has transitioned from close conversations to obligatory waves in the hall. Adding insult to the situation, Chunk was assigned as Freddy’s biology lab partner and tasked with holding extra study sessions to help improve the jock’s flunking grade.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 4067?
                        <>
                            <h2>(#{props.item}) Lana Ellison</h2>
                            <div className='holder'>
                            <p>Diana grew up in England and when she boarded a plan to New Orleans forty years ago, she had no idea that she would never go back. While on her trip she met a handsome policeman named Pete Washer and fell in love. They eventually laid down roots in Portstown, with Pete joining the force and Diana opening up a small art studio Downtown. Eventually the kids came along.  At first, it seemed like the Washers were living the American Dream. However, as family life grew more busy, Diana had less time for her art and shut down the studio. Without an outlet to express herself, she felt like a piece of her was missing. She resented Pete’s long hours, and when he would come home drunk late at night, the two would end up in epic fights. One night, things escalated and got violent. Diana took the kids and left.  After the divorce was finalized, she thought about going back to her art, but she never fully had it in her to rebuild the studio.  She ended up taking a job at the department store in the mall, working for Lana Ellison. The two became close friends, and Diana is now managing her Mayoral campaign.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 4259?
                        <>
                            <h2>(#{props.item}) Allan Tarman</h2>
                            <div className='holder'>
                            <p>Allan is the largest, strongest man in The Hills community. He is generally quiet and keeps to himself, being naturally mistrustful of others. He lives in a homemade underground bunker, which he crafted to avoid the radio signals of the "outsiders" who are trying to read his thoughts.  Six years ago, Allan went missing for two weeks. Since his return, he has been subject to violent bursts of epilepsy that require large amounts of sedatives to control. He has no recollection of his whereabouts during that time, but claims to have been experimented on. He is fiercely loyal to Trashie, who is the only person he truly trusts. He has also befriended Marilyn Breslin, and feels drawn to act as her protector, although he’s unsure from what.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 4405?
                        <>
                            <h2>(#{props.item}) Jerry Z. Pitt</h2>
                            <div className='holder'>
                            <p>No one is quite sure what Jerry does at the mansion. He has always been seen roaming the grounds, even after the family abandoned the property in the wake of Rupert O’Dea’s death. Rumor has it that Jerry knows all the secrets of the O’Dea family and is intimately involved in their financial and business dealings. He speaks with an unusual accent, but it changes frequently.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 4577?
                        <>
                            <h2>(#{props.item}) Scuzzer Peck</h2>
                            <div className='holder'>
                            <p>Most members of The Hills do not fit well in normal society. Scuzzer, however, barely fits in with the members of The Hills. He spends his days fiddling with shortwave radio and other signaling devices. While Scuzzer has been overheard transmitting broadcasts and quoting military jargon, it is unclear exactly who he is communicating with (or if anyone is actually on the receiving end).  For all the time he spends wearing a headset, talking into his microphone, he rarely speaks a word to anyone in the community. The only time he was noted to experience a connection is when Rock Breslin returned to The Hills to raise Marilyn. The two would often sit around a fire and get drunk at night. After Rock went to prison, Scuzzer withdrew to his small cottage, which many in the community jokingly refer to as the "Radio Shack".</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }
                        {leaderState == 5459?
                        <>
                            <h2>(#{props.item}) Sludge</h2>
                            <div className='holder'>
                            <p>The woman known as "Sludge" washed up on the Portstown shore during the tail end of Hurricane Katrina. After spending days in a coma in the Portstown General ICU, she woke up a total amnesic. With no recollection of her name, family, or home, she was discharged to the devastated streets of Portstown.  Lacking a source of food or clean water, she eventually sought shelter in a FEMA station run out of the St. Lazarus Church. It was there that she met Helena Morgan, who was drawn to this lost soul without a memory. After a prayer session, Helena anointed her with the name, Slaine, meaning "good health" and offered her respite in the Morgan’s guest bedroom. While Slaine was grateful for the hospitality, prayer and churchlife simply wasn’t in her blood. A few weeks later, when Slaine arrived at the Sunday dinner table drunk with track marks on her arm, her welcome had officially been worn out.  Slaine eventually found a small studio flat and her real "home" among the Blades at the Brain Bucket. She rose through the ranks of the gang, gaining respect, a reputation for deviousness, and the new name "Sludge". However, she never forgot the kindness of Helena Morgan. When Helena’s son, Glen, strolled into the Bucket one night, clearly at his breaking point, Sludge extended the same compassion. Under her tutelage, the crumbling teenager became a steel "knife" – one that, years later, she craftily manipulates to subtly maintain control over the group.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }

                        {leaderState == 101?
                        <>
                            <h2>(#{props.item}) Test Me now</h2>
                            <div className='holder'>
                            <p>OH you Better Believe it.</p>
                            </div>
                        </>
                        :
                        <div className='do-not-display'>
                            <h1>Not signed in.</h1>
                        </div>
                        }

                    </div>




                </div>


        </>
    )

}
export default CharacterDisplayTwo;
