import axios from 'axios';

export default axios.create({
    baseURL: 'https://eth-walkers-v2.herokuapp.com/api/gameapi2/s1.5/game'
});




///// Season 1.5:
///// https://eth-walkers-v2.herokuapp.com/api/gameapi2/s1.5/game


//// season 1:
//// https://eth-walkers-public-api.herokuapp.com/game
