import React, { useState, useEffect } from 'react';
import './CharacterActions1.css';
import axios from 'axios';

import api from '../api/walker_images';
import api2 from '../api/walker_backstory';

function CharacterDisplay() {



    const returnedCharacters = [{
            "image": "https://gateway.pinata.cloud/ipfs/QmbFWvmQpxgvESLUPZBkynMH9DFvDWMB6TqNoXRFzYs8YN",
            "tokenId": "1459",
            "name": "ETH Walker #1459",
            "attributes": [
                {"trait_type": "Class", "value": "Human"},
                {"trait_type": "Background", "value": "The Yacht Club"},
                {"trait_type": "Sex", "value": "Male"},
                {"trait_type": "Skin Tone", "value": "Five"},
                {"trait_type": "Body", "value": "Normal Body"},
                {"trait_type": "Hair", "value": "Style P"},
                {"trait_type": "Facial Hair", "value": "Facial Hair Style H"},
                {"trait_type": "Hair Color", "value": "Color 10"},
                {"trait_type": "Head", "value": "Head 13"},
                {"trait_type": "Brows", "value": "Brows 5"},
                {"trait_type": "Eyes", "value": "Eyes 12"},
                {"trait_type": "Pupils", "value": "Pupil 4"},
                {"trait_type": "Cheeks", "value": "Cheeks 5"},
                {"trait_type": "Mouth", "value": "Mouth 2"},
                {"trait_type": "Jaw", "value": "Jaw 14"},
                {"trait_type": "Nose", "value": "Nose 11"},
                {"trait_type": "Ears", "value": "Ear 4"},
                {"trait_type": "Age", "value": "Middle Age"},
                {"trait_type": "Outfit", "value": "Tactleneck 2"},
                {"trait_type": "Glasses", "value": "None"},
                {"trait_type": "Hat", "value": "None"},
                {"trait_type": "Leader", "value": "None"},
                {"trait_type": "Weapons", "value": "None"}],
            "combat": "none",
            "attack": "none"
        }];
    const [sendingCharacters, setSendingCharacters] = useState(returnedCharacters);
    const [searchedWalker, setSearchedWalker] = useState(0);
    const [currentImage, setCurrentImage] = useState([]);
    const [currentText, setCurrentText] = useState([]);




    const handleClick = () => {
        //console.log('Hello there!');



        const fetchCount = async () => {
            try {

                const response = await api.get('/'+searchedWalker);
                setCurrentImage(response.data.attributes.image_uri);

            } catch (err) {

            }
        }
        fetchCount();
        const fetchBackstory = async () => {
            try {

                const response = await api2.get('/'+searchedWalker);
                setCurrentText(response.data);

            } catch (err) {

            }
        }
        fetchBackstory();

    };




    return (

        <>

                <div className='character-display'>


                    <h1>Searching for Eth-Walker: {searchedWalker}</h1>
                    <input
                        type="number"
                        min="0"
                        max="10000"
                        value={searchedWalker}
                        onChange={ e => setSearchedWalker(e.target.value) }

                    ></input>
                    <button onClick={handleClick}>view</button>
                    <div className='gallery-container'>


                        <img className="characterPic2" src={currentImage}/>
                        <h2>{currentText.name}</h2>
                        <div className='holder'>
                        <p2>{currentText.backstory}</p2>
                        </div>

                    </div>


                </div>


        </>
    )

}
export default CharacterDisplay;
