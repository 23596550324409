import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import CharacterDisplayTwo from './CharacterDisplayTwo';
import CharacterDisplayMall from './CharacterDisplayMall';
import './factionGalleryEntranceNEW.css';
import api from '../api/walker_images';
import api2 from '../api/walker_backstory';
import api3 from '../api/walker_by_location';
import api4 from '../api/walker_by_faction';

import img2 from '../assets/map_hills_blown.png';
import { useHistory } from 'react-router-dom';

import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";


import img1 from '../assets/ethwalkers-wordmark.png';


import FactionGalleryPageCrawdaddy from './FactionGalleryCrawdaddy';
import FactionGalleryPageBelleRiver from './FactionGalleryBelleRiver';
import FactionGalleryPageConway from './FactionGalleryConway';
import FactionGalleryPageShield from './FactionGalleryShield';
import FactionGalleryPageBlade from './FactionGalleryBlade';
import FactionGalleryPageOutlanders from './FactionGalleryOutlanders';
import FactionGalleryPageRelic from './FactionGalleryRelic';
import FactionGalleryPageBrigade from './FactionGalleryBrigade';
import FactionGalleryPageVendetta from './FactionGalleryVendetta';
import FactionGalleryPagePortstown from './FactionGalleryPortstown';



function FactionGalleryPageEntrance(props) {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [byLocHere, setByLocHere] = useState(false)





    const [saleStarted, setSaleStarted] = useState(false)//

    const [publicSaleStarted, setPublicSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//



    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggetsl, setNuggetsl] = useState([]);


    const [characters, setCharacters] = useState([]);

    const [walkersExist, setWalkersExist] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("none");

    const [currentLocationWalkers, setCurrentLocationWalkers] = useState([]);


    const realnow = Date.now();
    const now = realnow/1000;

    const history = useHistory();



    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)
        console.log(exists)
        return exists;
    }








    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);



//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);

        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    function b(idToSearch) {
        return checkedNuggets.filter(item => {
            return item.id === idToSearch
        })
    };

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)


        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }

        const saleTime = await walkerContract.methods.publicSale().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setPublicSaleStarted(true)
        }


        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)





    }

    async function mintDuck() {
        if (walkerContract) {

            const price = Number(getPrice)  * how_many_ducks

            const gasAmount = await walkerContract.methods.mintETHWalkers(how_many_ducks).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .mintETHWalkers(how_many_ducks)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })


        } else {
            console.log("Wallet not connected")
        }

    };
    async function verifyDuckOwner(tokenNumber) {
        if (nuggetContract) {
            const owner = await nuggetContract.methods.ownerOf(tokenNumber).call({from: walletAddress})
            console.log(owner)
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            if (owner.toLowerCase() === checker) {
                setOwner(true)
                console.log("you own this")
            } else {
                setOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            console.log(nuggetsOfOwner)
            const fetchRedeemable_nuggets = async () => {
                try {
                    const response = await api.get('/redeemable_nuggets');
                    setRedeemable_nuggets(response.data);
                    console.log(redeemable_nuggets);
                    response.data.forEach((item) =>{
                        console.log(item.id)
                    })

                    setCheckedNuggets(checkedNuggets => [])
                    setRedeemableState(redeemableState => [])
                    let k = 0;
                    for (let i = 0; i < nuggetsOfOwner.length; i++) {
                        response.data.forEach((item) =>{
                            if (item.id == nuggetsOfOwner[i]) {
                                console.log(nuggetsOfOwner[i])

                                setRedeemableState(redeemableState => [ ...redeemableState, {
                                    select: false,
                                    id: k,
                                    nugget: "Redeem Nerdy Nugget " + nuggetsOfOwner[i],
                                    number: nuggetsOfOwner[i]
                                }])
                                k++;
                                console.log(k)

                            } else {

                            }
                        })

                    }




                } catch (err) {
                    if (err.response) {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else {
                        console.log(`Error: ${err.message}`);
                    }

                }
            }
            fetchRedeemable_nuggets();
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            console.log(walletAddress)
            if (walletAddress === checker) {
                setOwner(true)
                console.log("you own nuggets")

            } else {
                setOwner(false)
                console.log("you do not own nuggets")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function deleteNuggets() {
        checkedNuggets.forEach((item) =>{
            handleDelete(item.number)
        })
        setCheckedNuggets(checkedNuggets => [])
        setRedeemableState(redeemableState => [])

    }
    const handleDelete = async (id) => {
        try {
            await api.delete(`/redeemable_nuggets/${id}`);
            const nuggetsList = redeemable_nuggets.filter(redeemable_nuggets => redeemable_nuggets.id !== id);
            setNuggets(nuggetsList);
            history.push('/privateMinting');
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }

    }


    const handleClick3 = () => {
        //console.log('Hello there!');
        console.log(saleTime)
        console.log(now)
        console.log(publicSaleStarted)
    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        nuggetsOwned(walletAddress)
        console.log(redeemableState)
    };



    const handleClick = () => {
        //console.log('Hello there!');
        mintDuck()

    };
    const handleClick0 = () => {
        deleteNuggets()

    };
    const handleClick89 = () => {



    }

    /////START NEW Logic
    const handleClickCrawdaddy = () => {
        setShowCrawdaddy(true)
        setHideEverything(true)
    }
    const handleClickBelle = () => {
        setShowBelle(true)
        setHideEverything(true)
    }
    const handleClickConway = () => {
        setShowConway(true)
        setHideEverything(true)
    }
    const handleClickShield = () => {
        setShowShield(true)
        setHideEverything(true)
    }
    const handleClickBlade = () => {
        setShowBlade(true)
        setHideEverything(true)
    }
    const handleClickOutlanders = () => {
        setShowOutlanders(true)
        setHideEverything(true)
    }
    const handleClickRelic = () => {
        setShowRelic(true)
        setHideEverything(true)
    }
    const handleClickBrigade = () => {
        setShowBrigade(true)
        setHideEverything(true)
    }
    const handleClickVendetta = () => {
        setShowVendetta(true)
        setHideEverything(true)
    }
    const handleClickPortstown = () => {
        setShowPortstown(true)
        setHideEverything(true)
    }




    const [hideEverything, setHideEverything] = useState(false)
    const [showCrawdaddy, setShowCrawdaddy] = useState(false)
    const [showBelle, setShowBelle] = useState(false)
    const [showConway, setShowConway] = useState(false)
    const [showShield, setShowShield] = useState(false)
    const [showBlade, setShowBlade] = useState(false)
    const [showOutlanders, setShowOutlanders] = useState(false)
    const [showRelic, setShowRelic] = useState(false)
    const [showBrigade, setShowBrigade] = useState(false)
    const [showVendetta, setShowVendetta] = useState(false)
    const [showPortstown, setShowPortstown] = useState(false)




    async function realHideEverythingState(hideEverything) {
        setHideEverything(hideEverything);
    }
    async function realCrawdaddyState(showCrawdaddy) {
        setShowCrawdaddy(showCrawdaddy);
    }
    async function realBelleState(showBelle) {
        setShowBelle(showBelle);
    }
    async function realConwayState(showConway) {
        setShowConway(showConway);
    }
    async function realShieldState(showShield) {
        setShowShield(showShield);
    }
    async function realBladeState(showBlade) {
        setShowBlade(showBlade);
    }
    async function realOutlandersState(showOutlanders) {
        setShowOutlanders(showOutlanders);
    }
    async function realRelicState(showRelic) {
        setShowRelic(showRelic);
    }
    async function realBrigadeState(showBrigade) {
        setShowBrigade(showBrigade);
    }
    async function realVendettaState(showVendetta) {
        setShowVendetta(showVendetta);
    }
    async function realPortstownState(showPortstown) {
        setShowPortstown(showPortstown);
    }






    ////END NEW LOGIC




    const [returnedIds, setReturnedIds] = useState([])

    const [returnedIds2, setReturnedIds2] = useState([1, 34, 2545, 56, 56, 678, 2345]);
    const [hills, setHills] = useState([]);
    const [mall, setMall] = useState([]);
    const [nuggets, setNuggets] = useState([]);
    const [police, setPolice] = useState([]);
    const [penitentiary, setPenitentiary] = useState([]);
    const [estate, setEstate] = useState([]);
    const [university, setUniversity] = useState([]);
    const [downtown, setDowntown] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [mine, setMine] = useState([]);
    const [yacht, setYacht] = useState([]);
    const [hall, setHall] = useState([]);
    const [bucket, setBucket] = useState([]);
    const [returnedHills, setReturnedHills] = useState([]);
    const [returnedMall, setReturnedMall] = useState([]);
    const [returnedNuggets, setReturnedNuggets] = useState([]);
    const [returnedPolice, setReturnedPolice] = useState([]);
    const [returnedPenitentiary, setReturnedPenitentiary] = useState([]);
    const [returnedEstate, setReturnedEstate] = useState([]);
    const [returnedUniversity, setReturnedUniversity] = useState([]);
    const [returnedDowntown, setReturnedDowntown] = useState([]);
    const [returnedHospital, setReturnedHospital] = useState([]);
    const [returnedMine, setReturnedMine] = useState([]);
    const [returnedYacht, setReturnedYacht] = useState([]);
    const [returnedHall, setReturnedHall] = useState([]);
    const [returnedBucket, setReturnedBucket] = useState([]);
    const [hillsHere, setHillsHere] = useState(false);
    const [mallHere, setMallHere] = useState(false);
    const [nuggetsHere, setNuggetsHere] = useState(false);
    const [policeHere, setPoliceHere] = useState(false);
    const [penitentiaryHere, setPenitentiaryHere] = useState(false);
    const [estateHere, setEstateHere] = useState(false);
    const [universityHere, setUniversityHere] = useState(false);
    const [downtownHere, setDowntownHere] = useState(false);
    const [hospitalHere, setHospitalHere] = useState(false);
    const [mineHere, setMineHere] = useState(false);
    const [yachtHere, setYachtHere] = useState(false);
    const [hallHere, setHallHere] = useState(false);
    const [bucketHere, setBucketHere] = useState(false);
    const [memberCount1, setMemberCount1] = useState(0)
    const [memberCount2, setMemberCount2] = useState(0)
    const [memberCount3, setMemberCount3] = useState(0)
    const [memberCount4, setMemberCount4] = useState(0)
    const [memberCount5, setMemberCount5] = useState(0)
    const [memberCount6, setMemberCount6] = useState(0)
    const [memberCount7, setMemberCount7] = useState(0)
    const [memberCount8, setMemberCount8] = useState(0)
    const [memberCount9, setMemberCount9] = useState(0)
    const [memberCount10, setMemberCount10] = useState(0)






    useEffect(() => {
        const fetchHills1 = async () => {
            try {

                const response = await api4.get('/Crawdaddy Faction');

                setMemberCount1(response.data.length)



            } catch (err) {

            }
        }
        fetchHills1();
        const fetchHills2 = async () => {
            try {

                const response = await api4.get('/Belle River Faction');

                setMemberCount2(response.data.length)



            } catch (err) {

            }
        }
        fetchHills2();
        const fetchHills3 = async () => {
            try {

                const response = await api4.get('/Conway Faction');

                setMemberCount3(response.data.length)



            } catch (err) {

            }
        }
        fetchHills3();
        const fetchHills4 = async () => {
            try {

                const response = await api4.get('/Shield Faction');

                setMemberCount4(response.data.length)



            } catch (err) {

            }
        }
        fetchHills4();
        const fetchHills5 = async () => {
            try {

                const response = await api4.get('/Blade Faction');

                setMemberCount5(response.data.length)



            } catch (err) {

            }
        }
        fetchHills5();
        const fetchHills6 = async () => {
            try {

                const response = await api4.get('/Outlanders Faction');

                setMemberCount6(response.data.length)



            } catch (err) {

            }
        }
        fetchHills6();
        const fetchHills7 = async () => {
            try {

                const response = await api4.get('/Relic Faction');

                setMemberCount7(response.data.length)



            } catch (err) {

            }
        }
        fetchHills7();
        const fetchHills8 = async () => {
            try {

                const response = await api4.get('/Brigade Faction');

                setMemberCount8(response.data.length)



            } catch (err) {

            }
        }
        fetchHills8();
        const fetchHills9 = async () => {
            try {

                const response = await api4.get('/Vendetta Faction');

                setMemberCount9(response.data.length)



            } catch (err) {

            }
        }
        fetchHills9();
        const fetchHills10 = async () => {
            try {

                const response = await api4.get('/Portstown Faction');

                setMemberCount10(response.data.length)



            } catch (err) {

            }
        }
        fetchHills10();






        }, []);

    useEffect(() => {
        async function refreshPage(){
             window.location.reload(false);

         }


    }, []);
    const [ locationKeys, setLocationKeys ] = useState([])
    useEffect(() => {
      return history.listen(location => {
        if (history.action === 'PUSH') {
          setLocationKeys([ location.key ])
        }

        if (history.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([ _, ...keys ]) => keys)

            // Handle forward event
            window.location.reload(false);

          } else {
            setLocationKeys((keys) => [ location.key, ...keys ])

            // Handle back event
            window.location.reload(false);

          }
        }
      })
    }, [ locationKeys, ])


    const handleClickBack = () => {
        props.realFactionGalleryState(false)
        props.realHideMeNowState(false)
    }







return (
    <>
    {!hideEverything?
    <div className='new-body85656'>
        <div className='title-backer'>
            <div className='lil-guy'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--small'
                    onClick={handleClickBack}
                >
                Back
                </Button>
            </div>
            <img className="type-pic1" src={img1} alt="not shown"/>
        </div>


        <div className='sub-body'>
            <div className='hero-container-Private'>


                <h2>Faction Tracking Page</h2>
                <p>(Click a faction below to see who's in it.)</p>
                <div className="big-present">


                <div className='list-container'>


                <button className="loc-list" onClick={handleClickCrawdaddy}>1. Crawdaddy Faction (Total Members: {memberCount1})</button>
                <button className="loc-list" onClick={handleClickBelle}>2. Belle River Faction (Total Members: {memberCount2})</button>
                <button className="loc-list" onClick={handleClickConway}>3. Conway Faction (Total Members: {memberCount3})</button>
                <button className="loc-list" onClick={handleClickShield}>4. Shield Faction (Total Members: {memberCount4})</button>
                <button className="loc-list" onClick={handleClickBlade}>5. Blade Faction (Total Members: {memberCount5})</button>
                <button className="loc-list" onClick={handleClickOutlanders}>6. Outlanders Faction (Total Members: {memberCount6})</button>
                <button className="loc-list" onClick={handleClickRelic}>7. Relic Faction (Total Members: {memberCount7})</button>
                <button className="loc-list" onClick={handleClickBrigade}>8. Brigade Faction (Total Members: {memberCount8})</button>
                <button className="loc-list" onClick={handleClickVendetta}>9. Vendetta Faction (Total Members: {memberCount9})</button>
                <button className="loc-list" onClick={handleClickPortstown}>10. Portstown Faction (Total Members: {memberCount10})</button>

                </div>

                </div>

                <div className="display-character">
                </div>







            </div>
        </div>
    </div>
    :
    <div className='do-not-display'>
        <h3>Not signed in.</h3>
    </div>
    }

    {showCrawdaddy?

        <FactionGalleryPageCrawdaddy realCrawdaddyState={realCrawdaddyState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }

    {showBelle?

        <FactionGalleryPageBelleRiver realBelleState={realBelleState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }

    {showConway?

        <FactionGalleryPageConway realConwayState={realConwayState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showShield?

        <FactionGalleryPageShield realShieldState={realShieldState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showBlade?

        <FactionGalleryPageBlade realBladeState={realBladeState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showOutlanders?

        <FactionGalleryPageOutlanders realOutlandersState={realOutlandersState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showRelic?

        <FactionGalleryPageRelic realRelicState={realRelicState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showBrigade?

        <FactionGalleryPageBrigade realBrigadeState={realBrigadeState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showVendetta?

        <FactionGalleryPageVendetta realVendettaState={realVendettaState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    {showPortstown?

        <FactionGalleryPagePortstown realPortstownState={realPortstownState} realHideEverythingState={realHideEverythingState}/>
        :
        <div className='do-not-display'>
            <h3>Not signed in.</h3>
        </div>
    }
    </>



);
}
export default FactionGalleryPageEntrance;
