import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import CharacterActions1 from './CharacterActions1';
import './combatPage.css';
import api from '../api/redeemable_nuggets';
import { useHistory } from 'react-router-dom';

import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";


import img1 from '../assets/ethwalkers-wordmark.png';


function CombatPage() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //
    const [otherWalletAddress, setOtherWalletAddress] = useState(null)
    const [signature, setSignature] = useState(null)

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)





    const [saleStarted, setSaleStarted] = useState(false)//

    const [publicSaleStarted, setPublicSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//



    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggets, setNuggets] = useState([]);


    const [characters, setCharacters] = useState([]);

    const [walkersExist, setWalkersExist] = useState(false);


    const realnow = Date.now();
    let gun = new Date();
    const now = realnow/1000;
    let gun2 = new Date().getHours();

    const history = useHistory();
    //console.log(now);
    //console.log(gun2);



    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)
        console.log(exists)
        return exists;
    }








    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);



//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);

        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            let otherWallet = Web3.utils.toChecksumAddress(wallet)
            setOtherWalletAddress(otherWallet)
            setSignedIn(true)
            callContractData(wallet)

        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    function b(idToSearch) {
        return checkedNuggets.filter(item => {
            return item.id === idToSearch
        })
    };

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)


        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }

        const saleTime = await walkerContract.methods.publicSale().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setPublicSaleStarted(true)
        }


        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)
        const signature = await window.web3.eth.personal.sign("Do Brains Taste good?", wallet, "test password!")
        setSignature(signature)






    }

    async function mintDuck() {
        if (walkerContract) {

            const price = Number(getPrice)  * how_many_ducks

            const gasAmount = await walkerContract.methods.mintETHWalkers(how_many_ducks).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .mintETHWalkers(how_many_ducks)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })


        } else {
            console.log("Wallet not connected")
        }

    };
    async function verifyDuckOwner(tokenNumber) {
        if (nuggetContract) {
            const owner = await nuggetContract.methods.ownerOf(tokenNumber).call({from: walletAddress})
            console.log(owner)
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            if (owner.toLowerCase() === checker) {
                setOwner(true)
                console.log("you own this")
            } else {
                setOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            console.log(nuggetsOfOwner)
            const fetchRedeemable_nuggets = async () => {
                try {
                    const response = await api.get('/redeemable_nuggets');
                    setRedeemable_nuggets(response.data);
                    console.log(redeemable_nuggets);
                    response.data.forEach((item) =>{
                        console.log(item.id)
                    })

                    setCheckedNuggets(checkedNuggets => [])
                    setRedeemableState(redeemableState => [])
                    let k = 0;
                    for (let i = 0; i < nuggetsOfOwner.length; i++) {
                        response.data.forEach((item) =>{
                            if (item.id == nuggetsOfOwner[i]) {
                                console.log(nuggetsOfOwner[i])

                                setRedeemableState(redeemableState => [ ...redeemableState, {
                                    select: false,
                                    id: k,
                                    nugget: "Redeem Nerdy Nugget " + nuggetsOfOwner[i],
                                    number: nuggetsOfOwner[i]
                                }])
                                k++;
                                console.log(k)

                            } else {

                            }
                        })

                    }




                } catch (err) {
                    if (err.response) {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else {
                        console.log(`Error: ${err.message}`);
                    }

                }
            }
            fetchRedeemable_nuggets();
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            console.log(walletAddress)
            if (walletAddress === checker) {
                setOwner(true)
                console.log("you own nuggets")

            } else {
                setOwner(false)
                console.log("you do not own nuggets")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function deleteNuggets() {
        checkedNuggets.forEach((item) =>{
            handleDelete(item.number)
        })
        setCheckedNuggets(checkedNuggets => [])
        setRedeemableState(redeemableState => [])

    }
    const handleDelete = async (id) => {
        try {
            await api.delete(`/redeemable_nuggets/${id}`);
            const nuggetsList = redeemable_nuggets.filter(redeemable_nuggets => redeemable_nuggets.id !== id);
            setNuggets(nuggetsList);
            history.push('/privateMinting');
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }

    }


    const handleClick3 = () => {
        //console.log('Hello there!');
        console.log(saleTime)
        console.log(now)
        console.log(publicSaleStarted)
    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        nuggetsOwned(walletAddress)
        console.log(redeemableState)
    };



    const handleClick = () => {
        //console.log('Hello there!');
        mintDuck()

    };
    const handleClick0 = () => {
        deleteNuggets()

    };
    const {initialMinute = 0,initialSeconds = 0} = 0;
    const [hours, setHours ] = useState(21-gun.getHours());
    const [minutes, setMinutes ] =  useState(60 - gun.getMinutes());
    const [seconds, setSeconds ] =  useState(60 - gun.getSeconds());
    //useEffect(()=>{
    //    let myInterval = setInterval(() => {
    //            if (hours < 0){
    //                setHours(hours*(-1)+21)
    //            }
    //            if (seconds > 0) {
    //                setSeconds(seconds - 1);
    //            }
    //            if (seconds === 0) {
    //                if (minutes === 0) {
    //                    setHours(hours - 1)
    //                    setMinutes(59);
    //                    setSeconds(59);
    //                } else {
    //                    setMinutes(minutes - 1);
    //                    setSeconds(59);
    //                }
    //            }
    //            if (seconds === 0) {
    //                if (minutes === 0){
    //                    if (hours === 0){
    //                        clearInterval(myInterval)
    //                    }
//
//                    }
//                }
//            }, 1000)
//            return ()=> {
//                clearInterval(myInterval);
//              };
//        });
return (
    <div className='new-body7'>
        <div className='title-backer'>
            <div className='lil-guy'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--small'
                    onClick={() => {history.push('/')}}
                >
                Back
                </Button>
            </div>
            <img className="type-pic1" src={img1} alt="not shown"/>
        </div>
        <div className='mama'></div>

        <div className='sub-body'>
            <div className='hero-container-Private'>


                <h2>ACTION SELECTION</h2>
                {/*}<div>
                   { minutes === 0 && seconds === 0
                       ? null
                       : <h1> {hours}:{minutes < 10 ?  `0${minutes}` :minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
                   }
               </div>*/}




                <div className="hero-btns">
                {!signedIn ?
                <Button
                    onClick={signIn}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Connect Wallet with Metamask
                </Button>
                : <Button
                    onClick={signOut}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Wallet Connected: {walletAddress}
                </Button>

                }
                <p>Be sure to sign the metamask signature request. If you do not, submitted changes will have no effect. (If you missed it click the button above to re-sign in.)</p>
                <h2>Important:</h2>
                <h3>When you click Submit Changes, make sure you see changes in the "Currently Set Actions:" field (If you don't see them, your actions are not set, try clicking submit again)</h3>
                </div>
                <div className="display-character-selectors">
                {signedIn?
                <CharacterActions1 address={walletAddress} signature={signature} otherWalletAddress={otherWalletAddress}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>



                }
                </div>




            </div>
        </div>
    </div>



);
}
export default CombatPage;
