import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './Mint-test.css';
import {ADDRESS, ABI} from "../config.js"
import { useHistory } from "react-router-dom";
import Authenticate from  './walletAuthenticator';
import {useProvider} from '../hooks/useProvider';
import {useSigner} from '../hooks/useSigner';

import img1 from '../assets/ethwalkers-wordmark.png';








function Mint() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [duckContract, setDuckContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [saleStarted, setSaleStarted] = useState(false)//

    const [getPrice, setDuckPrice] = useState(0)//

    const [getOwner, setTokenOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [provider, setProvider] = useProvider(window.ethereum);
    const [signer, updateSigner] = useSigner(provider);

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const duckContract = new window.web3.eth.Contract(ABI, ADDRESS)
        setDuckContract(duckContract)

        const salebool = await duckContract.methods.saleIsActive().call()
        // console.log("saleisActive" , salebool)
        setSaleStarted(salebool)

        const totalSupply = await duckContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await duckContract.methods.getPrice().call()
        setDuckPrice(getPrice)


    }

    async function mintDuck(how_many_ducks) {
        if (duckContract) {

            const price = Number(getPrice)  * how_many_ducks

            const gasAmount = await duckContract.methods.mintDepressedDuck(how_many_ducks).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            duckContract.methods
                .mintDepressedDuck(how_many_ducks)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };
    async function verifyDuckOwner(tokenNumber) {
        if (duckContract) {
            const owner = await duckContract.methods.ownerOf(tokenNumber).call({from: walletAddress})

            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)

            if (owner.toLowerCase() == checker) {
                setTokenOwner(true)
                console.log("you own this")
            } else {
                setTokenOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }

    let history = useHistory();



const handleClick2 = () => {
    //console.log('Hello there!');

};

const handleClick = () => {
    //console.log('Hello there!');

};
return (
    <div className='new-body5'>
        <div className='title-backer'>

            <img className="type-pic1" src={img1} alt="not shown"/>

        </div>
        <div className='mama'></div>
        <div className='sub-body'>

            <div className='hero-container-Private'>

                <h2>Game Play Starting 6/22/22</h2>




            



            </div>
        </div>
    </div>




);
}
export default Mint;

/*// <div id="div1">
// <img className="duck__image1" src={img2} alt="not shown"/>
// <img className="duck__image1" src={img3} alt="not shown"/>
// <img className="duck__image1" src={img4} alt="not shown"/>
// </div>
// <div id="div2">
// <img className="duck__image2" src={img6} alt="not shown"/>
// <img className="duck__image2" src={img7} alt="not shown"/>
// <img className="duck__image2" src={img5} alt="not shown"/>
// </div>
// <div id="div3">
// </div>*/
