import React, { useState, useEffect } from 'react';
import './NewsJournal.css';

import img1 from '../assets/ethwalkers-wordmark.png';
import img2 from '../assets/Journal_Entry_1.png';
import img3 from '../assets/Journal_Entry_June_22_PM_Part_1.png';
import img4 from '../assets/Journal_Entry_June_22_PM_Part_2.png';
import img5 from '../assets/Journal_Entry_June_24.png';
import img6 from '../assets/Journal_Entry_June_25.png';
import img7 from '../assets/Journal_Entry_June_26b.png';
import img8 from '../assets/Journal_Entry_June_27.png';
import img9 from '../assets/Journal_Entry_June_28.png';
import img10 from '../assets/Journal_Entry_June_30.png';
import img11 from '../assets/Journal_Entry_July_1.png';
import img12 from '../assets/Journal_Entry_July_2.png';
import img13 from '../assets/Journal_Entry_July_3_(part_1).png';
import img14 from '../assets/Journal_Entry_July_3_(part_2).png';
import img15 from '../assets/Journal_Entry_July_4_(part_1_AM).png';
import img16 from '../assets/Journal_Entry_July_4_(part_2_PM).png';
import img17 from '../assets/Journal_Entry_July_5_(part_1_AM).png';
import img18 from '../assets/Journal_Entry_July_6.png';
import img19 from '../assets/Journal_Entry_July_7_(part_1).png';
import img20 from '../assets/Journal_Entry_July_7_(page_2).png';



import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

function NewsDisplayJournal(props) {


    const history = useHistory();


    const handleClickBack = () => {
        props.setRealNewsPageState(true);
        props.setRealJournalPageState(false);
    }





    return (

        <>


                <div className='news-body'>
                <div className='title-backer'>
                    <div className='lil-guy'>
                        <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--small'
                            onClick={handleClickBack}
                        >
                        Back
                        </Button>
                    </div>
                    <img className="type-pic1" src={img1} alt="not shown"/>
                </div>
                    <h1 style={{ paddingTop: '30px', color: 'white'}}>Portstown Journal Entries</h1>
                    <div className='news-section'>
                        <img className="type-pic2" src={img19} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Fire... Fire Everywhere.. (pt1)</h1>
                        <img className="type-pic2" src={img20} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Fire... Fire Everywhere.. (pt2)</h1>
                        <img className="type-pic2" src={img18} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>The Tattoo, the Final clue... </h1>
                        <img className="type-pic2" src={img17} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry -Word Phrase, The Legend... </h1>
                        <img className="type-pic2" src={img16} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry -Candace has the Antidote</h1>
                        <img className="type-pic2" src={img15} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry -They Raided the Storage Unit</h1>
                        <img className="type-pic2" src={img13} alt="not shown"/>
                        <img className="type-pic2" src={img14} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry -The Artifacts Explained</h1>
                        <img className="type-pic2" src={img12} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #10</h1>
                        <img className="type-pic2" src={img11} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #9</h1>
                        <img className="type-pic2" src={img10} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #8</h1>
                        <img className="type-pic2" src={img9} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #7</h1>
                        <img className="type-pic2" src={img8} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #6</h1>
                        <img className="type-pic2" src={img7} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #5</h1>
                        <img className="type-pic2" src={img6} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #4</h1>
                        <img className="type-pic2" src={img5} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #3</h1>
                        <img className="type-pic2" src={img3} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #2 pt1</h1>
                        <img className="type-pic2" src={img4} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #2 pt2</h1>
                        <img className="type-pic2" src={img2} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #1</h1>
                    </div>
                </div>




        </>
    )

}
export default NewsDisplayJournal;
