import { useEffect, useState } from "react";
import { ethers } from "ethers";

export const useProvider = (wndEthereum) => {
  const [provider, setProvider] = useState(null);


  const updateProvider = () => {
    let p = null;
    if (wndEthereum !== undefined) {
      p = new ethers.providers.Web3Provider(wndEthereum, "any");

      setProvider(p);
    }
    return p;
  };

  useEffect(() => {
    const p = updateProvider();
    const onNetworkHandler = () => {
      // console.log("got network change");
      p.off('network', onNetworkHandler)
      updateProvider();
    }
    if (p !== null) {
      p.on("network", onNetworkHandler);
    }
  }, [wndEthereum]);

  return [provider, setProvider];
};
