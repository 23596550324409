import React, { useState, useEffect } from 'react';
import './NewsRadio.css';

import img1 from '../assets/ethwalkers-wordmark.png';
import img2 from '../assets/Journal_Entry_1.png';
import img3 from '../assets/Journal_Entry_June_22_PM_Part_1.png';
import img4 from '../assets/Journal_Entry_June_22_PM_Part_2.png';
import img5 from '../assets/radio_asset.png';

import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

function NewsDisplayRadio(props) {


    const history = useHistory();


    const handleClickBack = () => {
        props.setRealNewsPageState(true)
        props.setRealRadioPageState(false)
    }





    return (

        <>


                <div className='news-body'>
                <div className='title-backer'>
                    <div className='lil-guy'>
                        <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--small'
                            onClick={handleClickBack}
                        >
                        Back
                        </Button>
                    </div>
                    <img className="type-pic1" src={img1} alt="not shown"/>
                </div>
                    <h1 style={{ paddingTop: '30px', color: 'white'}}>Portstown Radio</h1>
                    <div className='news-section'>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341874/0b80c75a34' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcasts #7 (Get to the Docks!)</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341833/d681b3edd0' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcasts #6 (Military Base Closing.)</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341935/591b704ac4' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Candace Carson Communication #1</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341791/d25386602d' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcasts #5 (Airstrike on The Facility.)</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341735/60336cc097' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcasts #4 (The Hills are a Deadzone)</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341701/e6b426fe72' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcast #3</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341663/91831013e4' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcast #2</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/725341619/4ff4947efe' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>ShortWave Broadcast #1</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/672427130/860556364c' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #10</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/672904303' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #9</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666406107' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #8</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402079' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #7</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402073' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #6</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402064' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #5</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402047' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #4</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402089' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #3</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402031' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #2</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/658038578' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #1</h1>

                    </div>
                </div>




        </>
    )

}
export default NewsDisplayRadio;
