import { SiweMessage } from "siwe";
import { kBackendAddr } from "../constants";

const domain = document.location.host;
const origin = document.location.origin;
// provider and signer

function createSiweMessage(address, statement) {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: "1",
  });
  // return message.prepareMessage();
  return message;
}

export async function signInWithEthereum(signer) {
  const nonce_res = await fetch(`${kBackendAddr}/api/auth/nonce`, {
    method: "GET",
    credentials: "include",
  }).then((response) => response.json());

  const message = await createSiweMessage(
    await signer.getAddress(),
    "Ethwalkers Sign In for Season 1.5!"
  );
  message.nonce = nonce_res.nonce;
  const signature = await signer.signMessage(message.prepareMessage());

  // const contents = await fetch(`${kBackendAddr}/api/auth/login`, {
  const contents = await fetch(`${kBackendAddr}/api/auth/login`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message, signature }),
  })
    .then(async (res) => {
      if (res.status === 200) {
        return true;
      } else {
        return res.json().catch((err) => {
          console.error(err);
          return err;
        });
        // return false;
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  // console.log("contents", contents);
  // console.log(await res.text());
  return contents;
}

export const getMeInfo = async () => {
  // need to catch errors
  return await fetch(`${kBackendAddr}/api/auction/me`, {
    mode: "cors",
    credentials: "include",
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((contents) => {
          // connectedState(text, ens ?? address);
          console.log("inner contents", contents);
          return contents;
        });
      }
      return;
    })
    .catch((err) => {
      // console.error("error", err);
      return null;
    });
};

export const postSignOut = async () => {
  return fetch(`${kBackendAddr}/api/auth/logout`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      return true;
    })
    .catch((err) => {
      return true;
    });
};
