import React, { useState, useEffect } from 'react';
import './CharacterDisplayFour.css';
import axios from 'axios';

import api from '../api/walker_images';
import api2 from '../api/walker_backstory';
import api3 from '../api/walker_by_location';

function CharacterDisplayTwo(props) {



    const returnedCharacters = [{
            "image": "https://gateway.pinata.cloud/ipfs/QmbFWvmQpxgvESLUPZBkynMH9DFvDWMB6TqNoXRFzYs8YN",
            "tokenId": "1459",
            "name": "ETH Walker #1459",
            "attributes": [
                {"trait_type": "Class", "value": "Human"},
                {"trait_type": "Background", "value": "The Yacht Club"},
                {"trait_type": "Sex", "value": "Male"},
                {"trait_type": "Skin Tone", "value": "Five"},
                {"trait_type": "Body", "value": "Normal Body"},
                {"trait_type": "Hair", "value": "Style P"},
                {"trait_type": "Facial Hair", "value": "Facial Hair Style H"},
                {"trait_type": "Hair Color", "value": "Color 10"},
                {"trait_type": "Head", "value": "Head 13"},
                {"trait_type": "Brows", "value": "Brows 5"},
                {"trait_type": "Eyes", "value": "Eyes 12"},
                {"trait_type": "Pupils", "value": "Pupil 4"},
                {"trait_type": "Cheeks", "value": "Cheeks 5"},
                {"trait_type": "Mouth", "value": "Mouth 2"},
                {"trait_type": "Jaw", "value": "Jaw 14"},
                {"trait_type": "Nose", "value": "Nose 11"},
                {"trait_type": "Ears", "value": "Ear 4"},
                {"trait_type": "Age", "value": "Middle Age"},
                {"trait_type": "Outfit", "value": "Tactleneck 2"},
                {"trait_type": "Glasses", "value": "None"},
                {"trait_type": "Hat", "value": "None"},
                {"trait_type": "Leader", "value": "None"},
                {"trait_type": "Weapons", "value": "None"}],
            "combat": "none",
            "attack": "none"
        }];
    const [sendingCharacters, setSendingCharacters] = useState(returnedCharacters);
    const [searchedWalker, setSearchedWalker] = useState(props.item.tokenId);
    const [currentImage, setCurrentImage] = useState([]);
    const [currentText, setCurrentText] = useState([]);
    const [currentLocation, setCurrentLocation] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const [dataHere, setDataHere] = useState(false)






    useEffect(() => {
        let isUnmount = false;
        if(!isUnmount){
        const fetchCount = async () => {
            try {

                const response = await api.get('/'+props.item.tokenId);
                setCurrentImage(response.data);

            } catch (err) {

            }
        }
        fetchCount();
        const fetchBackstory = async () => {
            try {

                const response = await api2.get('/'+props.item.tokenId);
                setCurrentText(response.data);

            } catch (err) {

            }
        }
        fetchBackstory();
    }
    return () => {
        isUnmount = true;
        console.log('cleanup')
    };
    }, []);






    return (

        <>

                <div className='character-display-location'>






                    <div className='gallery-container'>



                        <img className="characterPic57" src={currentImage.image}/>

                        <h2>Leader Kills: {props.item.LeaderKills}</h2>
                        <h2>Human Kills: {props.item.Kills}</h2>
                        <h2>(#{props.item.tokenId})</h2>
                        <div className='holder'>

                        </div>

                    </div>




                </div>


        </>
    )

}
export default CharacterDisplayTwo;
