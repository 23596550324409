import { useLocalStorage } from '../hooks/useLocalStorage';
import { signInWithEthereum } from '../helpers/loginToApi';
import React, { useCallback } from "react";
import {useAddress} from "../hooks/useAddress";

import { Button } from './Button';


export default function Authenticate(props) {
  const {
    signer, provider, updateSigner
  } = props
  const signerAddress = useAddress(signer);


  const handleClickSigner2 = () => {
      props.setSetSelectorState(true)
      props.setSignedInSignerState(true)


  }
  const superSignIn = async() => {
      const derp = await requestFirstAccess()
      console.log(derp, "derp")
      signIn()
  }

  const [auth, setAuth] = useLocalStorage("siwe_auth", null);
  const requestFirstAccess = useCallback(
    (event) => {
      function handleAccountsChanged(newAccounts) {
        console.log("handleAccountsChanged", newAccounts);
        if (newAccounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log("Please connect to MetaMask.");
      } else if (newAccounts[0] !== signerAddress) {
          // setCurrentAccount(newAccounts[0]);
          return updateSigner();
        }
      }

        if (!provider) {
          console.error("Invalid provider chosen");
          return;
        }
        return provider.provider
          .request({ method: "eth_requestAccounts" })
          .then(handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              console.log("Please connect to MetaMask.");
            } else {
              console.error(err);
            }
          });


    },
    [provider, signer, updateSigner]
  );
  console.log("auth", auth);
  let authSection = null;
  const signIn = useCallback(
    async (event) => {
      const details = await signInWithEthereum(signer);
      console.log("details", details);
      setAuth(details);
    },
    [signer]
  );
  if(!signerAddress){
      authSection = (
        <div>
          <button onClick={superSignIn} >
            Login to Metamask
          </button>
        </div>
      );
  }else if (auth === null) {

    // if not authenticated
    // login
    authSection = (
      <div>
        <button onClick={signIn} >
          Login to Website
        </button>
      </div>
    );
  } else {
    authSection = <div><div style={{ padding: '20px' }}>You're logged in</div>
        <Button

            onClick={handleClickSigner2}
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--medium'
        >PLAY
        </Button>
    </div>;
  }


  return <div >{authSection}</div>;
}
