import React, { useState, useEffect } from 'react';
import './NewView.css';
import axios from 'axios';
import img1 from '../assets/ethwalkers-wordmark.png';
import img2 from '../assets/Lit_Map_BURN.png';

import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import api from '../api/walker_images';
import api2 from '../api/numbers_by_location';




import api4 from '../api/walker_characters';

//new additions
import CharacterBackstory from './CharacterBackstory';
import CharacterBackstoryCard from './CharacterBackstoryCard';

import CharacterSelectorSection from './CharacterSelectorSection';

import LocationGalleryPageEntrance from './locationGalleryEntranceNEW';
import FactionGalleryPageEntrance from './factionGalleryEntranceNEW';
import ZombieGalleryPage from './zombieGalleryNEW';
import NewsDisplay from './News';
import GalleryPageNew from './galleryNEW';


import Web3 from "web3";
import CharacterActions2 from './CharacterActions2';
import CharacterActions2Batch from './CharacterActions2Batch';
import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";
import api3 from '../api/redeemable_nuggets';


import { useAddress } from "../hooks/useAddress";
import Authenticate from  './walletAuthenticator';
import {useProvider} from '../hooks/useProvider';
import {useSigner} from '../hooks/useSigner';
import {postSignOut} from '../helpers/loginToApi';

function NewView() {


    const [provider, setProvider] = useProvider(window.ethereum);
    const [signer, updateSigner] = useSigner(provider);
    const signerAddress = useAddress(signer);
    //added constants
    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //
    const [otherWalletAddress, setOtherWalletAddress] = useState(null)
    const [signature, setSignature] = useState(null)

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)





    const [saleStarted, setSaleStarted] = useState(false)//

    const [publicSaleStarted, setPublicSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//



    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggets, setNuggets] = useState([]);


    const [characters, setCharacters] = useState([]);

    const [walkersExist, setWalkersExist] = useState(false);


    const realnow = Date.now();
    let gun = new Date();
    const now = realnow/1000;
    let gun2 = new Date().getHours();





    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)
        console.log(exists)
        return exists;
    }

    const [selector, setSelector] = useState(false);
    ////END OF ADDED CONSTANTS


    ///SELECTOR LOGIC
    const [returnedCharacters2, setReturnedCharacters2] = useState([]);
    const [sendingCharacters, setSendingCharacters] = useState([]);
    const [backStoryState, setBackStoryState] = useState('');
    const [characterNameState, setCharacterNameState] = useState('');
    const [currentSignature, setCurrentSignature] = useState(null)
    const [currentImage, setCurrentImage] = useState([]);


    async function nameState(characterNameState) {
        setCharacterNameState(characterNameState);
    }
    async function realBackStoryState(backStoryState) {
        setBackStoryState(backStoryState);
    }


    const superSignature = JSON.stringify(signature)
    const superAddress= JSON.stringify(walletAddress)

    useEffect(() => {
        const fetchCount = async () => {
            try {

                const response = await api4.get('/'+walletAddress);

                console.log(response.data)

            } catch (err) {

            }
        }
        fetchCount();

    }, [selector]);

    useEffect(() => {
        setSendingCharacters(sendingCharacters => [])
        returnedCharacters2.map(data => {
            console.log(data.tokenId);
            //console.log(returnedCharacters2);
            setSendingCharacters(sendingCharacters => [ ...sendingCharacters, {
                TokenId: data.tokenId,
                TokenData: {
                  Name: "none",
                  Backstory: "none"
                }

            }])

        })

    }, [returnedCharacters2]);
    var axios = require('axios');

    const displayCards = returnedCharacters2.map((item, index) =>
        <CharacterBackstoryCard item={item} otherWalletAddress2={otherWalletAddress} sendingCharacters2={sendingCharacters}
        characterName={nameState} characterBackstory={realBackStoryState} address={walletAddress} signature={signature} index={index}/>
    )

    async function realMainToken(mainTokenState) {
        setMainTokenState(mainTokenState);
        console.log(mainTokenState);
    }
    const [mainTokenState, setMainTokenState] = useState(null)
    const [viewIndividual, setViewIndividual] = useState(true)
    const [viewBatch, setViewBatch] = useState(false)


    const handleClickShowBatch = () => {
        setViewIndividual(false)
        setViewBatch(true)
    }
    const handleClickShowIndividual = () => {
        setViewIndividual(true)
        setViewBatch(false)
    }
    async function setViewIndividualState(viewIndividual) {
        setViewIndividual(viewIndividual);
    }
    async function setViewBatchState(viewBatch) {
        setViewBatch(viewBatch);
    }
    ////END SELECTOR LOGIC

    //// Location logic
    const [hideMeNow, setHideMeNow] = useState(false)
    const [showLocationGallery, setShowLocationGallery] = useState(false)
    const [showFactionGallery, setShowFactionGallery] = useState(false)
    const [showZombieGallery, setShowZombieGallery] = useState(false)
    const [showNews, setShowNews] = useState(false)
    const [showGallery, setShowGallery] = useState(false)

    const handleClick75 = () => {
        window.localStorage.clear();
        window.location.reload(false);
    }
    const handleClickGallery = () => {
        setHideMeNow(true)
        setShowGallery(true)
    }

    const handleClickLocationGallery = () => {
        setHideMeNow(true)
        setShowLocationGallery(true)
    }
    const handleClickFactionGallery = () => {
        setHideMeNow(true)
        setShowFactionGallery(true)
    }
    const handleClickZombieGallery = () => {
        setHideMeNow(true)
        setShowZombieGallery(true)
    }
    const handleClickShowNews = () => {
        setHideMeNow(true)
        setShowNews(true)
    }
    async function setSignedInSignerState(signedInSigner) {
        setSignedInSigner(signedInSigner);
    }
    async function setSetSelectorState(selector) {
        setSelector(selector);
    }

    async function realGalleryState(showGallery) {
        setShowGallery(showGallery);
    }
    async function realHideMeNowState(hideMeNow) {
        setHideMeNow(hideMeNow);
    }
    async function realLocationGalleryState(showLocationGallery) {
        setShowLocationGallery(showLocationGallery);
    }
    async function realFactionGalleryState(showFactionGallery) {
        setShowFactionGallery(showFactionGallery);
    }
    async function realZombieGalleryState(showZombieGallery) {
        setShowZombieGallery(showZombieGallery);
    }
    async function realNewsState(showNews) {
        setShowNews(showNews);
    }
    ////End location Logic
    const [signedInSigner, setSignedInSigner] = useState(false);
    const handleClickSigner = () => {
        setSignedInSigner(true)
        setSelector(true)
        console.log(signer)
    }





    //original map constants below
    const history = useHistory();





    const [normal, setNormal] = useState(true);
    const [human, setHuman] = useState(false);
    const [zombie, setZombie] = useState(false);
    const [base, setBase] = useState([]);
    const [hills, setHills] = useState([]);
    const [mall, setMall] = useState([]);

    const [police, setPolice] = useState([]);
    const [penitentiary, setPenitentiary] = useState([]);
    const [estate, setEstate] = useState([]);
    const [university, setUniversity] = useState([]);
    const [downtown, setDowntown] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [mine, setMine] = useState([]);
    const [yacht, setYacht] = useState([]);
    const [hall, setHall] = useState([]);
    const [bucket, setBucket] = useState([]);
    const [facility, setFacility] = useState([]);



    const handleClick = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(false)
        setZombie(false)
        setNormal(true)


    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(true)
        setZombie(false)
        setNormal(false)
        //console.log(hills.Human)



    };
    const handleClick3 = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(false)
        setZombie(true)
        setNormal(false)



    };

    useEffect(() => {
        const fetchFacility = async () => {
            try {

                const response = await api2.get('/The Facility');
                setFacility(response.data);


            } catch (err) {

            }
        }
        fetchFacility();
        const fetchBase = async () => {
            try {

                const response = await api2.get('/Military Base');
                setBase(response.data);


            } catch (err) {

            }
        }
        fetchBase();
        const fetchHills = async () => {
            try {

                const response = await api2.get('/The Hills');
                setHills(response.data);


            } catch (err) {

            }
        }
        fetchHills();
        const fetchMall = async () => {
            try {

                const response = await api2.get('/Monroeville Shopping Mall');
                setMall(response.data);

            } catch (err) {

            }
        }
        fetchMall();
        const fetchNuggets = async () => {
            try {

                const response = await api2.get('/Nerdy Nuggets');
                setNuggets(response.data);

            } catch (err) {

            }
        }
        fetchNuggets();
        const fetchPolice = async () => {
            try {

                const response = await api2.get('/Police Station');
                setPolice(response.data);

            } catch (err) {

            }
        }
        fetchPolice();
        const fetchPenitentiary = async () => {
            try {

                const response = await api2.get('/Belle River Penitentiary');
                setPenitentiary(response.data);

            } catch (err) {

            }
        }
        fetchPenitentiary();
        const fetchEstate = async () => {
            try {

                const response = await api2.get('/Buckner Estate');
                setEstate(response.data);

            } catch (err) {

            }
        }
        fetchEstate();
        const fetchUniversity = async () => {
            try {

                const response = await api2.get('/Portstown University');
                setUniversity(response.data);

            } catch (err) {

            }
        }
        fetchUniversity();
        const fetchDowntown = async () => {
            try {

                const response = await api2.get('/Downtown');
                setDowntown(response.data);

            } catch (err) {

            }
        }
        fetchDowntown();
        const fetchHospital = async () => {
            try {

                const response = await api2.get('/Portstown General Hospital');
                setHospital(response.data);

            } catch (err) {

            }
        }
        fetchHospital();
        const fetchMine = async () => {
            try {

                const response = await api2.get('/The Old Coal Mine');
                setMine(response.data);

            } catch (err) {

            }
        }
        fetchMine();
        const fetchYacht = async () => {
            try {

                const response = await api2.get('/The Yacht Club');
                setYacht(response.data);

            } catch (err) {

            }
        }
        fetchYacht();
        const fetchHall = async () => {
            try {

                const response = await api2.get('/City Hall');
                setHall(response.data);

            } catch (err) {

            }
        }
        fetchHall();
        const fetchBucket = async () => {
            try {

                const response = await api2.get('/The Brain Bucket');
                setBucket(response.data);

            } catch (err) {

            }
        }
        fetchBucket();


        }, []);
    const z14 = base.Zombie
    const z1 = hills.Zombie
    const z2 = mall.Zombie
    const z3 = nuggets.Zombie
    const z4 = police.Zombie
    const z5 = penitentiary.Zombie
    const z6 = estate.Zombie
    const z7 = university.Zombie
    const z8 = downtown.Zombie
    const z9 = hospital.Zombie
    const z10 = mine.Zombie
    const z11 = yacht.Zombie
    const z12 = hall.Zombie
    const z13 = bucket.Zombie
    const z15 = facility.Zombie






    //added combat or sign in functions
    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);

        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            let otherWallet = Web3.utils.toChecksumAddress(wallet)
            setOtherWalletAddress(otherWallet)
            setSignedIn(true)
            callContractData(wallet)

        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }


    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)


        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }

        const saleTime = await walkerContract.methods.publicSale().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setPublicSaleStarted(true)
        }


        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)
        const signature = await window.web3.eth.personal.sign("Do Brains Taste good?", wallet, "test password!")
        setSignature(signature)
        setSelector(true)






    }
    const handleClickSelector = () => {
        setSelector(true)
    }

    return (

        <>


                {!hideMeNow?
                <div className='new-body101'>
                    <div className='title-backer'>
                        <div className='lil-guy'>
                            {/*<Button
                                className='btns'
                                buttonStyle='btn--outline'
                                buttonSize='btn--small'
                                onClick={() => {history.push('/')}}
                            >
                            Back
                            </Button>*/}
                        </div>
                        <img className="type-pic1" src={img1} alt="not shown"/>
                    </div>


                    <div className='sub-body'>
                        <div className='hero-container-Private'>



                            <div className='character-sidebar'>


                                {selector?
                                <CharacterSelectorSection setViewIndividualState={setViewIndividualState} setViewBatchState={setViewBatchState}
                                viewBatch={viewBatch} address={signerAddress}
                                signature={signature} otherWalletAddress2={signerAddress} realMainToken={realMainToken} mainTokenState={mainTokenState}/>
                                :
                                <div className='do-not-display'>
                                    <h3>Not signed in.</h3>
                                </div>
                                }
                                <div className="hero-btns">
                                {!signedInSigner ?
                                    <>

                                <Authenticate signer={signer} provider={provider} updateSigner={updateSigner} setSignedInSignerState={setSignedInSignerState}
                                setSetSelectorState={setSetSelectorState}/>
                                <div className='instruction-section'>
                                <p>1. Click "Login into MetaMask" and connect wallet
                                <br/>2. Click "Login into Website" and sign message
                                <br/>3. You should now see "You're logged in" - go ahead and click Play!
                                </p>
                                <div className='logout-button'>
                                <Button

                                    onClick={handleClick75}
                                    className='btns'
                                    buttonStyle='btn--outline'
                                    buttonSize='btn--medium'
                                >Sign out of the website
                                </Button>
                                <p>Use this button if you're switching wallets.</p>
                                </div>
                                </div>
                                </>
                                :
                                <div className='do-not-display'>
                                    <h3>Not signed in.</h3>
                                </div>

                                }
                                </div>
                                {mainTokenState != null && viewIndividual?
                                <>
                                    <Button

                                        onClick={handleClickShowBatch}
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                    >View all your characters at once
                                    </Button>
                                <CharacterActions2 address={signerAddress} signature={signature} otherWalletAddress={signerAddress} realMainToken={realMainToken}
                                mainTokenState={mainTokenState}/>
                                </>
                                :
                                <div className='do-not-display'>
                                    <h3>Not signed in.</h3>
                                </div>
                                }
                                {viewBatch?
                                    <>
                                        <Button

                                            onClick={handleClickShowIndividual}
                                            className='btns'
                                            buttonStyle='btn--outline'
                                            buttonSize='btn--medium'
                                        >View Characters Individually
                                        </Button>
                                <CharacterActions2Batch address={signerAddress} signature={signature} otherWalletAddress={signerAddress} realMainToken={realMainToken}
                                mainTokenState={mainTokenState}/>
                                </>
                                :
                                <div className='do-not-display'>
                                    <h3>Not signed in.</h3>
                                </div>
                                }


                            </div>

                            {mainTokenState != null?
                            <div className='character-display'>





                                <div className='gallery-container-view'>
                                    <div className='gallery-container2'>
                                    <div className='nav-list'>
                                    <div className='hero-btns'>
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                        onClick={handleClickShowNews}
                                    >
                                    Breaking News!
                                    </Button>
                                    </div>
                                    <div className='hero-btns'>
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                        onClick={handleClickLocationGallery}
                                    >
                                    Gallery By Location
                                    </Button>
                                    </div>
                                    <div className='hero-btns'>
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                        onClick={handleClickGallery}
                                    >
                                    Search By Id
                                    </Button>
                                    </div>

                                    <div className='hero-btns'>
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                        onClick={handleClickFactionGallery}
                                    >
                                    Faction Tracking Page
                                    </Button>
                                    </div>
                                    {/*
                                    <div className='hero-btns'>
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outline'
                                        buttonSize='btn--medium'
                                        onClick={handleClickZombieGallery}
                                    >
                                    Zombie Leaderboard
                                    </Button>

                                    </div>*/}
                                    </div>

                                        {normal ?
                                            <div className="map-wrapper">
                                            <div className="map-display">
                                            </div>
                                            {mainTokenState != null?
                                            <div className="new-display">
                                            <p style={{ color: 'white' }}>The below buttons will show the total number of walkers or zombies at each location.</p>

                                                <button className="normalstate" onClick={handleClick}>Normal View</button>
                                            <button className="humanstate" onClick={handleClick2}>Where are the people?</button>
                                            <button className="zombiestate" onClick={handleClick3}>Where are the Zombies?</button>

                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h3>Not signed in.</h3>
                                            </div>
                                            }
                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h1>Not signed in.</h1>
                                            </div>
                                        }
                                        {/*<img className="characterPic4" src={img2} alt="not shown"/>*/}
                                        {human ?
                                            <div className="map-wrapper">
                                            <div className="map-display">

                                                <div className='loc-1'><p>{hills.Human}</p></div>
                                                <div className='loc-2'><p>{mall.Human}</p></div>
                                                <div className='loc-3'><p>{nuggets.Human}</p></div>
                                                <div className='loc-4'><p>{police.Human}</p></div>
                                                <div className='loc-5'><p>{penitentiary.Human}</p></div>
                                                <div className='loc-6'><p>{estate.Human}</p></div>
                                                <div className='loc-7'><p>{university.Human}</p></div>
                                                <div className='loc-8'><p>{downtown.Human}</p></div>
                                                <div className='loc-9'><p>{hospital.Human}</p></div>
                                                <div className='loc-10'><p>{mine.Human}</p></div>
                                                <div className='loc-11'><p>{yacht.Human}</p></div>
                                                <div className='loc-12'><p>{hall.Human}</p></div>
                                                <div className='loc-13'><p>{bucket.Human}</p></div>
                                                <div className='loc-14'><p>{base.Human}</p></div>
                                                <div className='loc-15'><p>{facility.Human}</p></div>


                                            </div>
                                            {mainTokenState != null?
                                            <div className="new-display">
                                            <p style={{ color: 'white' }}>The below buttons will show the total number of walkers or zombies at each location.</p>

                                                <button className="normalstate" onClick={handleClick}>Normal View</button>
                                            <button className="humanstate" onClick={handleClick2}>Where are the people?</button>
                                            <button className="zombiestate" onClick={handleClick3}>Where are the Zombies?</button>

                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h3>Not signed in.</h3>
                                            </div>
                                            }
                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h1>Not signed in.</h1>
                                            </div>
                                        }
                                        {zombie ?
                                            <div className="map-wrapper">
                                            <div className="map-display">

                                                <div className='loc-1z'><p>{z1}</p></div>
                                                <div className='loc-2z'><p>{z2}</p></div>
                                                <div className='loc-3z'><p>{z3}</p></div>
                                                <div className='loc-4z'><p>{z4}</p></div>
                                                <div className='loc-5z'><p>{z5}</p></div>
                                                <div className='loc-6z'><p>{z6}</p></div>
                                                <div className='loc-7z'><p>{z7}</p></div>
                                                <div className='loc-8z'><p>{z8}</p></div>
                                                <div className='loc-9z'><p>{z9}</p></div>
                                                <div className='loc-10z'><p>{z10}</p></div>
                                                <div className='loc-11z'><p>{z11}</p></div>
                                                <div className='loc-12z'><p>{z12}</p></div>
                                                <div className='loc-13z'><p>{z13}</p></div>
                                                <div className='loc-14z'><p>{z14}</p></div>
                                                <div className='loc-15z'><p>{z15}</p></div>



                                            </div>
                                            {mainTokenState != null?
                                            <div className="new-display">
                                            <p style={{ color: 'white' }}>The below buttons will show the total number of walkers or zombies at each location.</p>

                                                <button className="normalstate" onClick={handleClick}>Normal View</button>
                                            <button className="humanstate" onClick={handleClick2}>Where are the people?</button>
                                            <button className="zombiestate" onClick={handleClick3}>Where are the Zombies?</button>

                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h3>Not signed in.</h3>
                                            </div>
                                            }
                                            </div>
                                            :
                                            <div className='do-not-display'>
                                                <h1>Not signed in.</h1>
                                            </div>
                                        }
                                        <div className="legend" >
                                            <h2>Legend</h2>
                                            <p>1. The Hills</p>
                                            <p>2. Monroeville Shopping Mall</p>
                                            <p>3. Nerdy Nuggets</p>
                                            <p>4. Portstown Police Station</p>
                                            <p>5. Belle River Penitentiary</p>
                                            <p>6. The Buckner Estate</p>
                                            <p>7. Portstown University</p>
                                            <p>8. Downtown</p>
                                            <p>9. Portstown General Hospital</p>
                                            <p>10. The Old Coal Mine</p>
                                            <p>11. The Yacht Club</p>
                                            <p>12. City Hall</p>
                                            <p>13. The Brain Bucket</p>
                                            <p>14. The Military Base</p>
                                            <p>15. The Facility</p>

                                        </div>




                                    </div>


                                </div>




                            </div>
                            :
                            <div className='do-not-display'>
                                <h3>Not signed in.</h3>
                            </div>
                            }







                        </div>

                    </div>


                </div>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>

            }
            {showLocationGallery?
                <LocationGalleryPageEntrance realHideMeNowState={realHideMeNowState} realLocationGalleryState={realLocationGalleryState} />
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }
            {showFactionGallery?
                <FactionGalleryPageEntrance realHideMeNowState={realHideMeNowState} realFactionGalleryState={realFactionGalleryState} />
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }
            {showZombieGallery?
                <ZombieGalleryPage realHideMeNowState={realHideMeNowState} realZombieGalleryState={realZombieGalleryState}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }

            {showNews?
                <NewsDisplay  realHideMeNowState={realHideMeNowState} realNewsState={realNewsState}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }
            {showGallery?
                <GalleryPageNew realHideMeNowState={realHideMeNowState} realGalleryState={realGalleryState}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }





        </>
    )

}
export default NewView;
