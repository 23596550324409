import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './presaleMintingSixPack.css';
import api from '../api/redeemable_nuggets';
import { useHistory } from 'react-router-dom';

import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";

import img1 from '../assets/ethwalkers-wordmark.png';



function PrivateMintingSixPack() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [saleStarted, setSaleStarted] = useState(false)//

    const [preSaleStarted, setPreSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//


    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [nuggetCount, setNuggetCount] = useState([]);

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggets, setNuggets] = useState([]);

    const history = useHistory();
    const now = Date.now();

    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)

        return exists;
    }

    useEffect(() => {
        const fetchRedeemable_nuggets = async () => {
            try {
                const response = await api.get('/redeemable_nuggets');
                setRedeemable_nuggets(response.data);



            } catch (err) {
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }

            }
        }




        fetchRedeemable_nuggets();
    }, []);

    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);



//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    function b(idToSearch) {
        return checkedNuggets.filter(item => {
            return item.id === idToSearch
        })
    };

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)

        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }


        const saleTime = await walkerContract.methods.presaleNuggetStart().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setPreSaleStarted(true)
        }

        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)


    }

    async function mintDuck() {
        if (walkerContract) {

            const price = Number(getPrice) * 0
            const nugIds = checkedNuggets.map((checkedNuggets) => {
                return checkedNuggets.id
            })





            const gasAmount = await walkerContract.methods.mintPreSaleRedeemSixPackNuggets(nugIds).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price * 0})

            walkerContract.methods
                .mintPreSaleRedeemSixPackNuggets(nugIds)
                .send({from: walletAddress, value: price * 0, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })
            deleteNuggets()

        } else {
            console.log("Wallet not connected")
        }

    };
    async function verifyDuckOwner(tokenNumber) {
        if (nuggetContract) {
            const owner = await nuggetContract.methods.ownerOf(tokenNumber).call({from: walletAddress})

            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)

            if (owner.toLowerCase() === checker) {
                setOwner(true)
                console.log("you own this")
            } else {
                setOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})




            setCheckedNuggets(checkedNuggets => [])
            setRedeemableState(redeemableState => [])
            let f = true;
            let k = 0;
            for (let i = 0; i < nuggetsOfOwner.length; i++) {
                f = await walkerContract.methods.getNuggetPartOfSixPackRedeemed(nuggetsOfOwner[i]).call()

                if (f == false) {
                    setRedeemableState(redeemableState => [ ...redeemableState, {
                        select: false,
                        id: nuggetsOfOwner[i],
                        nugget: "Redeem Nerdy Nugget " + nuggetsOfOwner[i],
                        number: nuggetsOfOwner[i]
                    }])
                    k++;


                } else {

                }
            }


        }else {
            console.log("Wallet not connected")
        }




    };
    async function deleteNuggets() {
        checkedNuggets.forEach((item) =>{
            handleDelete(item.number)
        })
        setCheckedNuggets(checkedNuggets => [])
        setRedeemableState(redeemableState => [])

    }
    async function testLoop() {
        setNuggetCount(nuggetCount=>[])
        checkedNuggets.forEach((item) =>{
            const id = (item.number);
            setNuggetCount(nuggetCount => [ ...nuggetCount, {
                id
            }])
        })

    };
    const handleDelete = async (id) => {
        try {
            await api.delete(`/redeemable_nuggets/${id}`);
            const nuggetsList = redeemable_nuggets.filter(redeemable_nuggets => redeemable_nuggets.id !== id);
            setNuggets(nuggetsList);
            history.push('/privateMinting');
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }

    }


    const handleClick3 = () => {
        //console.log('Hello there!');
        console.log(checkedNuggets)
        console.log(redeemableState)


    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        nuggetsOwned(walletAddress)

    };



    const handleClick = () => {
        //console.log('Hello there!');
        mintDuck()
    };
    const handleClick0 = () => {
        deleteNuggets()

    };
return (
    <div className='new-body2'>
        <div className='title-backer'>
            <div className='lil-guy'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--small'
                    onClick={() => {history.push('/Navigation')}}
                >
                BACK
                </Button>
            </div>
            <img className="type-pic1" src={img1} alt="not shown"/>

        </div>
        <div className='mama'></div>


        <div className='sub-body'>
            <div className='hero-container-Private'>


                <h2>SIX NUGGETS REDEMPTION!</h2>
                <div id="mint" className="flex">

                <span className="flex">Get one human for FREE for each six nuggets you redeem.</span>




                </div>
                <div className='hero-btns'>
                {preSaleStarted && saleStarted && checkedNuggets.length == 6 ?
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    onClick={handleClick}
                >
                MINT {checkedNuggets.length/6} Human For Free + GAS
                </Button>
                : <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >SALE IS NOT ACTIVE OR NO WALLET IS CONNECTED
                </Button>
                }
                </div>
                <div className="hero-btns">
                {!signedIn ?
                <Button
                    onClick={signIn}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Connect Wallet with Metamask
                </Button>
                : <Button
                    onClick={signOut}
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--medium'
                >Wallet Connected: {walletAddress}
                </Button>
                }
                </div>
                <span>You may redeem only six per TX.</span>
                <table className='table1'>
                    <tbody>
                        {
                            redeemableState.map((d, i) => (
                            <tr key={d.id}>
                                <th scope="row">
                                    <input onChange={event =>{
                                        let checked=event.target.checked;
                                        setRedeemableState(
                                            redeemableState.map(data => {
                                                if (d.id === data.id) {
                                                    if (data.select === true) {

                                                        const newCheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newCheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newCheckedNuggets);

                                                    }


                                                    data.select = checked;

                                                    setCheckedNuggets(checkedNuggets => [ ...checkedNuggets, {
                                                        id: data.id,

                                                    }])
                                                    if (checked === false) {

                                                        const newCheckedNuggets = [ ...checkedNuggets];
                                                        const index = checkedNuggets.findIndex((checkedNuggets)=> checkedNuggets.id === data.id);
                                                        newCheckedNuggets.splice(index, 1);
                                                        setCheckedNuggets(newCheckedNuggets);

                                                    }



                                                }





                                                return data;
                                            })
                                        );
                                    }} type="checkbox" checked={d.select}></input>
                                </th>
                                <td>{d.nugget}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                    <div className='hero-btns'>
                    {signedIn ?
                        <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--medium'
                            onClick={handleClick2}
                        >
                        Check what nuggets you can redeem
                        </Button>
                        : <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--large'
                        >NO WALLET IS CONNECTED
                        </Button>
                    }

                </div>
                <p>IMPORTANT: Nugget redemption is tracked on chain. If you have a contract interaction that hasn’t resolved yet those nuggets may still show as redeemable on our page.
                For your sake please do not attempt to redeem nuggets which you already have redeemed in a pending tx.</p>
                <p>Contract Address: 0x4691b302c37B53c68093f1F5490711d3B0CD2b9C</p>
            </div>
        </div>
    </div>



);
}
export default PrivateMintingSixPack;
