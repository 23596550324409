import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import CharacterDisplayFour from './CharacterDisplayFour';
import CharacterDisplayMall from './CharacterDisplayMall';
import './zombieGalleryNEW.css';
import api from '../api/walker_images';
import api2 from '../api/walker_backstory';
import api3 from '../api/walker_by_location';
import api4 from '../api/zombie_kills';
import { useHistory } from 'react-router-dom';

import {ADDRESS as ADDRESSONE, ABI as ABIONE} from "../testconfigtwo.js";
import {ADDRESS as ADDRESSTWO, ABI as ABITWO} from "../testconfig.js";


import img1 from '../assets/ethwalkers-wordmark.png';

import ReactPaginate from 'react-paginate';


function ZombieGalleryPage(props) {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_ducks, set_how_many_ducks] = useState(1)

    const [walkerContract, setwalkerContract] = useState(null)//

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [byLocHere, setByLocHere] = useState(false)





    const [saleStarted, setSaleStarted] = useState(false)//

    const [publicSaleStarted, setPublicSaleStarted] = useState(false)//

    const [saleTime, setSaleTime] = useState(1)//



    const [getPrice, setDuckPrice] = useState(0)//

    const [owner, setOwner] = useState(false)

    const [tokenNumber, setTokenNumber] = useState(0)

    const [redeemableState, setRedeemableState] = useState([]);

    const [redeemable, setRedeemable] = useState([]);

    const [checkedNuggets, setCheckedNuggets] = useState([]);

    const [redeemable_nuggets, setRedeemable_nuggets] = useState([]);

    const [nuggetsl, setNuggetsl] = useState([]);


    const [characters, setCharacters] = useState([]);

    const [walkersExist, setWalkersExist] = useState(false);
    const [currentLocation, setCurrentLocation] = useState("none");

    const [currentLocationWalkers, setCurrentLocationWalkers] = useState([]);


    const realnow = Date.now();
    const now = realnow/1000;

    const history = useHistory();



    const alreadyExists = (nuggetId) => {
        const exists = checkedNuggets.includes(nuggetId)
        console.log(exists)
        return exists;
    }








    useEffect(() => {
        setCheckedNuggets(
            checkedNuggets.map(d => {
                return {
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );



    }, []);

    useEffect(() => {



        setRedeemableState(
            redeemableState.map(d => {
                return {
                    select: false,
                    id: d.id,
                    nugget: d.nugget,
                };
            })
        );
    }, []);



//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);

        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }
    function b(idToSearch) {
        return checkedNuggets.filter(item => {
            return item.id === idToSearch
        })
    };

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const walkerContract = new window.web3.eth.Contract(ABIONE, ADDRESSONE)
        setwalkerContract(walkerContract)


        const sale = await walkerContract.methods.saleState().call()
        // console.log("saleisActive" , salebool)
        if (sale == 1){
            setSaleStarted(true)
        }

        const saleTime = await walkerContract.methods.publicSale().call()
        setSaleTime(saleTime)
        if (saleTime < now){
            setPublicSaleStarted(true)
        }


        const totalSupply = await walkerContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await walkerContract.methods.getPrice().call()
        setDuckPrice(getPrice)

        const nuggetContract = new window.web3.eth.Contract(ABITWO, ADDRESSTWO)
        setNuggetContract(nuggetContract)





    }

    async function mintDuck() {
        if (walkerContract) {

            const price = Number(getPrice)  * how_many_ducks

            const gasAmount = await walkerContract.methods.mintETHWalkers(how_many_ducks).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            walkerContract.methods
                .mintETHWalkers(how_many_ducks)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })


        } else {
            console.log("Wallet not connected")
        }

    };
    async function verifyDuckOwner(tokenNumber) {
        if (nuggetContract) {
            const owner = await nuggetContract.methods.ownerOf(tokenNumber).call({from: walletAddress})
            console.log(owner)
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            if (owner.toLowerCase() === checker) {
                setOwner(true)
                console.log("you own this")
            } else {
                setOwner(false)
                console.log("you  do not own this")
            }
        } else {
            console.log("Wallet not connected")
        }
    }
    async function nuggetsOwned(walletAddress) {
        if (nuggetContract) {
            const nuggetsOfOwner = await nuggetContract.methods.tokensOfOwner(walletAddress).call({from: walletAddress})
            console.log(nuggetsOfOwner)
            const fetchRedeemable_nuggets = async () => {
                try {
                    const response = await api.get('/redeemable_nuggets');
                    setRedeemable_nuggets(response.data);
                    console.log(redeemable_nuggets);
                    response.data.forEach((item) =>{
                        console.log(item.id)
                    })

                    setCheckedNuggets(checkedNuggets => [])
                    setRedeemableState(redeemableState => [])
                    let k = 0;
                    for (let i = 0; i < nuggetsOfOwner.length; i++) {
                        response.data.forEach((item) =>{
                            if (item.id == nuggetsOfOwner[i]) {
                                console.log(nuggetsOfOwner[i])

                                setRedeemableState(redeemableState => [ ...redeemableState, {
                                    select: false,
                                    id: k,
                                    nugget: "Redeem Nerdy Nugget " + nuggetsOfOwner[i],
                                    number: nuggetsOfOwner[i]
                                }])
                                k++;
                                console.log(k)

                            } else {

                            }
                        })

                    }




                } catch (err) {
                    if (err.response) {
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else {
                        console.log(`Error: ${err.message}`);
                    }

                }
            }
            fetchRedeemable_nuggets();
            const signature = await window.web3.eth.personal.sign("Hello world", walletAddress, "test password!")
            const checker = await window.web3.eth.personal.ecRecover("Hello world", signature)
            console.log(checker)
            console.log(walletAddress)
            if (walletAddress === checker) {
                setOwner(true)
                console.log("you own nuggets")

            } else {
                setOwner(false)
                console.log("you do not own nuggets")
            }

        } else {
            console.log("Wallet not connected")
        }
    };
    async function deleteNuggets() {
        checkedNuggets.forEach((item) =>{
            handleDelete(item.number)
        })
        setCheckedNuggets(checkedNuggets => [])
        setRedeemableState(redeemableState => [])

    }
    const handleDelete = async (id) => {
        try {
            await api.delete(`/redeemable_nuggets/${id}`);
            const nuggetsList = redeemable_nuggets.filter(redeemable_nuggets => redeemable_nuggets.id !== id);
            setNuggets(nuggetsList);
            history.push('/privateMinting');
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }

    }


    const handleClick3 = () => {
        //console.log('Hello there!');
        console.log(saleTime)
        console.log(now)
        console.log(publicSaleStarted)
    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        nuggetsOwned(walletAddress)
        console.log(redeemableState)
    };



    const handleClick = () => {
        //console.log('Hello there!');
        mintDuck()

    };
    const handleClick0 = () => {
        deleteNuggets()

    };
    const handleClick89 = () => {
        setCurrentLocation("The Yacht Club")


    }


    const handleClickBack = () => {
        props.realZombieGalleryState(false)
        props.realHideMeNowState(false)
    }




    const [returnedIds, setReturnedIds] = useState([])

    const [returnedIds2, setReturnedIds2] = useState([1, 34, 2545, 56, 56, 678, 2345]);
    const [hills, setHills] = useState([]);
    const [mall, setMall] = useState([]);
    const [nuggets, setNuggets] = useState([]);
    const [police, setPolice] = useState([]);
    const [penitentiary, setPenitentiary] = useState([]);
    const [estate, setEstate] = useState([]);
    const [university, setUniversity] = useState([]);
    const [downtown, setDowntown] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [mine, setMine] = useState([]);
    const [yacht, setYacht] = useState([]);
    const [hall, setHall] = useState([]);
    const [bucket, setBucket] = useState([]);
    const [returnedHills, setReturnedHills] = useState([]);
    const [returnedMall, setReturnedMall] = useState([]);
    const [returnedNuggets, setReturnedNuggets] = useState([]);
    const [returnedPolice, setReturnedPolice] = useState([]);
    const [returnedPenitentiary, setReturnedPenitentiary] = useState([]);
    const [returnedEstate, setReturnedEstate] = useState([]);
    const [returnedUniversity, setReturnedUniversity] = useState([]);
    const [returnedDowntown, setReturnedDowntown] = useState([]);
    const [returnedHospital, setReturnedHospital] = useState([]);
    const [returnedMine, setReturnedMine] = useState([]);
    const [returnedYacht, setReturnedYacht] = useState([]);
    const [returnedHall, setReturnedHall] = useState([]);
    const [returnedBucket, setReturnedBucket] = useState([]);
    const [hillsHere, setHillsHere] = useState(false);
    const [mallHere, setMallHere] = useState(false);
    const [nuggetsHere, setNuggetsHere] = useState(false);
    const [policeHere, setPoliceHere] = useState(false);
    const [penitentiaryHere, setPenitentiaryHere] = useState(false);
    const [estateHere, setEstateHere] = useState(false);
    const [universityHere, setUniversityHere] = useState(false);
    const [downtownHere, setDowntownHere] = useState(false);
    const [hospitalHere, setHospitalHere] = useState(false);
    const [mineHere, setMineHere] = useState(false);
    const [yachtHere, setYachtHere] = useState(false);
    const [hallHere, setHallHere] = useState(false);
    const [bucketHere, setBucketHere] = useState(false);

    const [zombies, setZombies] = useState([]);
    const [zombiesHere, setZombiesHere] = useState(false);
    const [returnedZombies, setReturnedZombies] = useState([]);
    const [infoHereFreind, setInfoHereFriend] = useState(false)

    //(zombies.length*140)/
    useEffect(() => {
        const renderZombies = async () => {
            try {
                for (let i = 0; i < zombies.length; i++) {
                    setReturnedZombies(returnedZombies => [ ...returnedZombies,
                        zombies[i].tokenId
                    ])
                }
                console.log("zombies set")

            } catch (err) {

            }
        }
        renderZombies();
    }, [currentLocation])






    useEffect(() => {

        const fetchZombie = async () => {
            try {

                const response = await api4.get('/getzombiekills');
                setZombies(response.data);
                console.log("info is here freind")
                setInfoHereFriend(true)
            } catch (err) {

            }
        }
        fetchZombie();

    }, []);
        const [ locationKeys, setLocationKeys ] = useState([])
        useEffect(() => {
          return history.listen(location => {
            if (history.action === 'PUSH') {
              setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
              if (locationKeys[1] === location.key) {
                setLocationKeys(([ _, ...keys ]) => keys)

                // Handle forward event
                window.location.reload(false);

              } else {
                setLocationKeys((keys) => [ location.key, ...keys ])

                // Handle back event
                window.location.reload(false);

              }
            }
          })
        }, [ locationKeys, ])


        const [pageNumber, setPageNumber] = useState(0);

        const cardsPerPage = 32;
        const cardsVisited = pageNumber * cardsPerPage;
        const displayCards = zombies.slice(cardsVisited, cardsVisited + cardsPerPage).map((item, index) =>
            <CharacterDisplayFour key={item.tokenId} item={item} location={currentLocation} locData={zombies} index={index} byLocHere={byLocHere}/>
        )


        const pageCount = Math.ceil(returnedZombies.length/cardsPerPage);
        const changePage = ({selected}) => {
            setPageNumber(selected)
        };
















return (
    <div className='new-body856'>
    <div className='lil-guy'>
        <Button
            className='btns'
            buttonStyle='btn--outline'
            buttonSize='btn--small'
            onClick={handleClickBack}
        >
        Back
        </Button>
    </div>
        <div className='title-backer'>

            <img className="type-pic1" src={img1} alt="not shown"/>
        </div>
        <div className='lil-guy67'>

        </div>


        <div className='sub-body'>
            <div className='hero-container-Private'>


                <h2>Welcome to The Hall of Brains</h2>
                <p>Showing Zombies in order from most kills to least kills. (Leader kills are worth more.)</p>


                {infoHereFreind ?
                <button className="show-people" onClick={handleClick89}>See The Zombies</button>
                :
                <button className="show-people" >Loading data.... (please wait a few seconds)</button>
                }


                <p>Please wait to click until you see the "See The Zombies" Button, If it doesn't appear after ten seconds, refresh the page and give it a few seconds. </p>

                <div>
                <h3>If you want to search by walker number go here:</h3>
                <div className="oops">
                <button
                    className='show-people'

                    onClick={() => {history.push('/GalleryZ425368SSQQPP');
                window.location.reload(false);}}
                >
                Search By Walker Number
                </button>
                </div>
                </div>

                {currentLocation === "The Yacht Club"?
                <div className="display-character">


                {displayCards}


                </div>
                :
                <div className='do-not-display'>
                    <h1>Not signed in.</h1>
                </div>
                }
                {currentLocation === "The Yacht Club"?
                <div className="bottom-boy">

                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}


                />



                </div>
                :
                <div className='do-not-display'>
                    <h1>Not signed in.</h1>
                </div>
                }







            </div>
        </div>
    </div>



);
}
export default ZombieGalleryPage;
