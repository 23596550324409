import React, { useState, useEffect } from 'react';
import './MapPage.css';
import axios from 'axios';
import img1 from '../assets/ethwalkers-wordmark.png';
import img2 from '../assets/map_military.png';

import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import api from '../api/walker_images';
import api2 from '../api/numbers_by_location';

function MapDisplay() {


    const history = useHistory();





    const [normal, setNormal] = useState(true);
    const [human, setHuman] = useState(false);
    const [zombie, setZombie] = useState(false);
    const [base, setBase] = useState([]);
    const [hills, setHills] = useState([]);
    const [mall, setMall] = useState([]);
    const [nuggets, setNuggets] = useState([]);
    const [police, setPolice] = useState([]);
    const [penitentiary, setPenitentiary] = useState([]);
    const [estate, setEstate] = useState([]);
    const [university, setUniversity] = useState([]);
    const [downtown, setDowntown] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [mine, setMine] = useState([]);
    const [yacht, setYacht] = useState([]);
    const [hall, setHall] = useState([]);
    const [bucket, setBucket] = useState([]);
    const [facility, setFacility] = useState([]);

    const handleClick = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(false)
        setZombie(false)
        setNormal(true)


    };
    const handleClick2 = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(true)
        setZombie(false)
        setNormal(false)
        //console.log(hills.Human)



    };
    const handleClick3 = () => {
        //console.log('Hello there!');
        //console.log("change map");
        setHuman(false)
        setZombie(true)
        setNormal(false)



    };
    useEffect(() => {
        const fetchFacility = async () => {
            try {

                const response = await api2.get('/The Facility');
                setFacility(response.data);


            } catch (err) {

            }
        }
        fetchFacility();
        const fetchBase = async () => {
            try {

                const response = await api2.get('/Military Base');
                setBase(response.data);


            } catch (err) {

            }
        }
        fetchBase();
        const fetchHills = async () => {
            try {

                const response = await api2.get('/The Hills');
                setHills(response.data);


            } catch (err) {

            }
        }
        fetchHills();
        const fetchMall = async () => {
            try {

                const response = await api2.get('/Monroeville Shopping Mall');
                setMall(response.data);

            } catch (err) {

            }
        }
        fetchMall();
        const fetchNuggets = async () => {
            try {

                const response = await api2.get('/Nerdy Nuggets');
                setNuggets(response.data);

            } catch (err) {

            }
        }
        fetchNuggets();
        const fetchPolice = async () => {
            try {

                const response = await api2.get('/Police Station');
                setPolice(response.data);

            } catch (err) {

            }
        }
        fetchPolice();
        const fetchPenitentiary = async () => {
            try {

                const response = await api2.get('/Belle River Penitentiary');
                setPenitentiary(response.data);

            } catch (err) {

            }
        }
        fetchPenitentiary();
        const fetchEstate = async () => {
            try {

                const response = await api2.get('/Buckner Estate');
                setEstate(response.data);

            } catch (err) {

            }
        }
        fetchEstate();
        const fetchUniversity = async () => {
            try {

                const response = await api2.get('/Portstown University');
                setUniversity(response.data);

            } catch (err) {

            }
        }
        fetchUniversity();
        const fetchDowntown = async () => {
            try {

                const response = await api2.get('/Downtown');
                setDowntown(response.data);

            } catch (err) {

            }
        }
        fetchDowntown();
        const fetchHospital = async () => {
            try {

                const response = await api2.get('/Portstown General Hospital');
                setHospital(response.data);

            } catch (err) {

            }
        }
        fetchHospital();
        const fetchMine = async () => {
            try {

                const response = await api2.get('/The Old Coal Mine');
                setMine(response.data);

            } catch (err) {

            }
        }
        fetchMine();
        const fetchYacht = async () => {
            try {

                const response = await api2.get('/The Yacht Club');
                setYacht(response.data);

            } catch (err) {

            }
        }
        fetchYacht();
        const fetchHall = async () => {
            try {

                const response = await api2.get('/City Hall');
                setHall(response.data);

            } catch (err) {

            }
        }
        fetchHall();
        const fetchBucket = async () => {
            try {

                const response = await api2.get('/The Brain Bucket');
                setBucket(response.data);

            } catch (err) {

            }
        }
        fetchBucket();


        }, []);
    const z14 = base.Zombie 
    const z1 = hills.Zombie
    const z2 = mall.Zombie
    const z3 = nuggets.Zombie
    const z4 = police.Zombie
    const z5 = penitentiary.Zombie
    const z6 = estate.Zombie
    const z7 = university.Zombie
    const z8 = downtown.Zombie
    const z9 = hospital.Zombie
    const z10 = mine.Zombie
    const z11 = yacht.Zombie
    const z12 = hall.Zombie
    const z13 = bucket.Zombie
    const z15 = facility.Zombie






    return (

        <>


                <div className='new-body101'>
                    <div className='title-backer'>
                        <div className='lil-guy'>
                            <Button
                                className='btns'
                                buttonStyle='btn--outline'
                                buttonSize='btn--small'
                                onClick={() => {history.push('/')}}
                            >
                            Back
                            </Button>
                        </div>
                        <img className="type-pic1" src={img1} alt="not shown"/>
                    </div>


                    <div className='sub-body'>
                        <div className='hero-container-Private'>


                            <h2>Map of Portstown</h2>


                            <div className='character-display'>



                                <p>The below buttons will show the total number of walkers or zombies at each location.</p>

                                    <button className="normalstate" onClick={handleClick}>Normal View</button>
                                <button className="humanstate" onClick={handleClick2}>Where are the people?</button>
                                <button className="zombiestate" onClick={handleClick3}>Where are the Zombies?</button>

                                <div className='gallery-container2'>
                                    {normal ?
                                        <div className="map-display">
                                        </div>
                                        :
                                        <div className='do-not-display'>
                                            <h1>Not signed in.</h1>
                                        </div>
                                    }
                                    {/*<img className="characterPic4" src={img2} alt="not shown"/>*/}
                                    {human ?
                                        <div className="map-display">

                                            <div className='loc-1'><p>{hills.Human}</p></div>
                                            <div className='loc-2'><p>{mall.Human}</p></div>
                                            <div className='loc-3'><p>{nuggets.Human}</p></div>
                                            <div className='loc-4'><p>{police.Human}</p></div>
                                            <div className='loc-5'><p>{penitentiary.Human}</p></div>
                                            <div className='loc-6'><p>{estate.Human}</p></div>
                                            <div className='loc-7'><p>{university.Human}</p></div>
                                            <div className='loc-8'><p>{downtown.Human}</p></div>
                                            <div className='loc-9'><p>{hospital.Human}</p></div>
                                            <div className='loc-10'><p>{mine.Human}</p></div>
                                            <div className='loc-11'><p>{yacht.Human}</p></div>
                                            <div className='loc-12'><p>{hall.Human}</p></div>
                                            <div className='loc-13'><p>{bucket.Human}</p></div>
                                            <div className='loc-14'><p>{base.Human}</p></div>
                                            <div className='loc-15'><p>{facility.Human}</p></div>


                                        </div>
                                        :
                                        <div className='do-not-display'>
                                            <h1>Not signed in.</h1>
                                        </div>
                                    }
                                    {zombie ?
                                        <div className="map-display">

                                            <div className='loc-1z'><p>{z1}</p></div>
                                            <div className='loc-2z'><p>{z2}</p></div>
                                            <div className='loc-3z'><p>{z3}</p></div>
                                            <div className='loc-4z'><p>{z4}</p></div>
                                            <div className='loc-5z'><p>{z5}</p></div>
                                            <div className='loc-6z'><p>{z6}</p></div>
                                            <div className='loc-7z'><p>{z7}</p></div>
                                            <div className='loc-8z'><p>{z8}</p></div>
                                            <div className='loc-9z'><p>{z9}</p></div>
                                            <div className='loc-10z'><p>{z10}</p></div>
                                            <div className='loc-11z'><p>{z11}</p></div>
                                            <div className='loc-12z'><p>{z12}</p></div>
                                            <div className='loc-13z'><p>{z13}</p></div>
                                            <div className='loc-14z'><p>{z14}</p></div>
                                            <div className='loc-15z'><p>{z15}</p></div>



                                        </div>
                                        :
                                        <div className='do-not-display'>
                                            <h1>Not signed in.</h1>
                                        </div>
                                    }




                                    <div className="legend">
                                        <h2>Legend</h2>
                                        <p>1. The Hills</p>
                                        <p>2. Monroeville Shopping Mall</p>
                                        <p>3. Nerdy Nuggets</p>
                                        <p>4. Portstown Police Station</p>
                                        <p>5. Belle River Penitentiary</p>
                                        <p>6. The Buckner Estate</p>
                                        <p>7. Portstown University</p>
                                        <p>8. Downtown</p>
                                        <p>9. Portstown General Hospital</p>
                                        <p>10. The Old Coal Mine</p>
                                        <p>11. The Yacht Club</p>
                                        <p>12. City Hall</p>
                                        <p>13. The Brain Bucket</p>
                                        <p>14. The Military Base</p>
                                        <p>15. The Facility</p>

                                    </div>
                                </div>



                            </div>


                            <div className="hero-btns">

                            </div>




                        </div>
                    </div>
                </div>



        </>
    )

}
export default MapDisplay;
