import React, { useState, useEffect } from 'react';
import './News.css';

import img1 from '../assets/ethwalkers-wordmark.png';
import img2 from '../assets/Journal_Entry_1.png';
import img3 from '../assets/Journal_Entry_June_22_PM_Part_1.png';
import img4 from '../assets/Journal_Entry_June_22_PM_Part_2.png';

import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import NewsDisplayRadio from './NewsRadio';
import NewsDisplayJournal from './NewsJournal';
import img5 from '../assets/radio_asset.png';
import img6 from '../assets/journal_asset.png';


function NewsDisplay(props) {


    const history = useHistory();


    const [newsPageState, setNewsPageState] = useState(true);
    const [radioPageState, setRadioPageState] = useState(false);
    const [journalPageState, setJournalPageState] = useState(false);


    const handleClickBack = () => {
        props.realNewsState(false)
        props.realHideMeNowState(false)
    }


    async function setRealNewsPageState(newsPageState) {
        setNewsPageState(newsPageState);
    }
    async function setRealRadioPageState(radioPageState) {
        setRadioPageState(radioPageState);
    }
    async function setRealJournalPageState(journalPageState) {
        setJournalPageState(journalPageState);
    }

    const handleClickRadio = () => {
        setNewsPageState(false)
        setRadioPageState(true)

    }
    const handleClickJournal = () => {
        setNewsPageState(false)
        setJournalPageState(true)
    }



    return (

        <>

        {newsPageState?
                <div className='news-body'>
                <div className='title-backer'>
                    <div className='lil-guy'>
                        <Button
                            className='btns'
                            buttonStyle='btn--outline'
                            buttonSize='btn--small'
                            onClick={handleClickBack}
                        >
                        Back
                        </Button>
                    </div>
                    <img className="type-pic1" src={img1} alt="not shown"/>
                </div>
                    <h1 style={{ paddingTop: '30px', color: 'white'}}>Portstown News</h1>
                    <div className='news-section'>
                        <div className='news-grid'>
                            <div>
                            <img className="type-pic7" src={img5} style={{paddingBottom: '40px'}} alt="not shown"/>
                            <Button
                                style={{marginTop: '20px'}}
                                onClick={handleClickRadio}
                                className='btns'
                                buttonStyle='btn--outline'
                                buttonSize='btn--medium'
                            >View Broadcasts
                            </Button>

                            </div>
                            <div>
                            <img className="type-pic7" src={img6} style={{paddingBottom: '58px'}} alt="not shown"/>
                            <Button
                                style={{marginTop: '20px'}}
                                onClick={handleClickJournal}
                                className='btns'
                                buttonStyle='btn--outline'
                                buttonSize='btn--medium'
                            >Study Journals
                            </Button>

                            </div>
                        </div>


                        {/*<ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/666402031' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #2</h1>
                        <ReactPlayer width='80vw' height='60vh'controls url='https://vimeo.com/658038578' />
                        <h1 style={{ marginTop: '2px', paddingBottom: '50px', color: 'white'}}>Broadcast #1</h1>
                        <img className="type-pic2" src={img3} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #2 pt1</h1>
                        <img className="type-pic2" src={img4} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #2 pt2</h1>
                        <img className="type-pic2" src={img2} alt="not shown"/>
                        <h1 style={{ marginTop: '-80px', paddingBottom: '50px', color: 'white'}}>Journal Entry #1</h1>*/}
                    </div>
                </div>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }

            {radioPageState?
                <NewsDisplayRadio  setRealNewsPageState={setRealNewsPageState} setRealRadioPageState={setRealRadioPageState}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }
            {journalPageState?
                <NewsDisplayJournal setRealNewsPageState={setRealNewsPageState} setRealJournalPageState={setRealJournalPageState}/>
                :
                <div className='do-not-display'>
                    <h3>Not signed in.</h3>
                </div>
            }


        </>
    )

}
export default NewsDisplay;
