import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import PrivateMintingSixPack from './components/presaleMintingSixPack';
import PrivateMinting from './components/presaleMintingNuggets';
import PreSaleMintingBAYC from './components/presaleMintingBAYC';
import PublicMinting from './components/publicsaleMinting';
import CombatPage from './components/combatPage';
import GalleryPage from './components/gallery';
import GalleryPageZ from './components/galleryZ';
import LocationGalleryPage from './components/locationGallery';
import LocationGalleryPageMall from './components/locationGalleryMall';
import LocationGalleryPageNuggets from './components/locationGalleryNuggets';
import LocationGalleryPagePolice from './components/locationGalleryPolice';
import LocationGalleryPagePenitentiary from './components/locationGalleryPenitentiary';
import LocationGalleryPageEstate from './components/locationGalleryEstate';
import LocationGalleryPageUniversity from './components/locationGalleryUniversity';
import LocationGalleryPageDowntown from './components/locationGalleryDowntown';
import LocationGalleryPageHospital from './components/locationGalleryHospital';
import LocationGalleryPageMine from './components/locationGalleryMine';
import LocationGalleryPageYacht from './components/locationGalleryYacht';
import LocationGalleryPageHall from './components/locationGalleryHall';
import LocationGalleryPageBucket from './components/locationGalleryBucket';
import LocationGalleryPageBase from './components/locationGalleryBase';
import LocationGalleryPageFacility from './components/locationGalleryFacility';
import LocationGalleryPageEntrance from './components/locationGalleryEntrance';
import ZombieGalleryPage from './components/zombieGallery';
import FactionGalleryPageEntrance from './components/factionGalleryEntrance';
import FactionGalleryPageCrawdaddy from './components/FactionGalleryCrawdaddy';
import FactionGalleryPageBelleRiver from './components/FactionGalleryBelleRiver';
import FactionGalleryPageConway from './components/FactionGalleryConway';
import FactionGalleryPageShield from './components/FactionGalleryShield';
import FactionGalleryPageBlade from './components/FactionGalleryBlade';
import FactionGalleryPageOutlanders from './components/FactionGalleryOutlanders';
import FactionGalleryPageRelic from './components/FactionGalleryRelic';
import FactionGalleryPageBrigade from './components/FactionGalleryBrigade';
import FactionGalleryPageVendetta from './components/FactionGalleryVendetta';
import FactionGalleryPagePortstown from './components/FactionGalleryPortstown';
import StoryInputPage from './components/storyInput';
import MapDisplay from './components/MapPage';
import NewView from './components/NewView';
import NewsDisplay from './components/News';

function App() {
  return (
      <>
          <Router>
              <Switch>
                  <Route path='/654' exact component={Home} />
                  <Route path='/NuggetPrivateMinting' exact component={PrivateMinting} />
                  <Route path='/NuggetPrivateMintingSixNuggetPack' exact component={PrivateMintingSixPack} />
                  <Route path='/PreSaleMintingBAYCMAYCZen' exact component={PreSaleMintingBAYC} />
                  <Route path='/Navigation1985kkl' exact component={PublicMinting} />
                  <Route path='/CombatPage425368SS' exact component={CombatPage} />
                  <Route path='/Gallery425368SSQQPP' exact component={GalleryPage} />
                  <Route path='/GalleryZ425368SSQQPP' exact component={GalleryPageZ} />
                  <Route path='/LocationGallery425368SSQQPPkkii' exact component={LocationGalleryPage} />
                  <Route path='/LocationGalleryMall425368SSQQPPkkii' exact component={LocationGalleryPageMall} />
                  <Route path='/LocationGalleryNuggets425368SSQQPPkkii' exact component={LocationGalleryPageNuggets} />
                  <Route path='/LocationGalleryPolice425368SSQQPPkkii' exact component={LocationGalleryPagePolice} />
                  <Route path='/LocationGalleryPenitentiary425368SSQQPPkkii' exact component={LocationGalleryPagePenitentiary} />
                  <Route path='/LocationGalleryEstate425368SSQQPPkkii' exact component={LocationGalleryPageEstate} />
                  <Route path='/LocationGalleryUniversity425368SSQQPPkkii' exact component={LocationGalleryPageUniversity} />
                  <Route path='/LocationGalleryDowntown425368SSQQPPkkii' exact component={LocationGalleryPageDowntown} />
                  <Route path='/LocationGalleryHospital425368SSQQPPkkii' exact component={LocationGalleryPageHospital} />
                  <Route path='/LocationGalleryMine425368SSQQPPkkii' exact component={LocationGalleryPageMine} />
                  <Route path='/LocationGalleryYacht425368SSQQPPkkii' exact component={LocationGalleryPageYacht} />
                  <Route path='/LocationGalleryHall425368SSQQPPkkii' exact component={LocationGalleryPageHall} />
                  <Route path='/ZombieGallery425368SSQQPPkkii' exact component={ZombieGalleryPage} />
                  <Route path='/LocationGalleryBucket425368SSQQPPkkii' exact component={LocationGalleryPageBucket} />
                  <Route path='/LocationGalleryBase425368SSQQPPkkii' exact component={LocationGalleryPageBase} />
                  <Route path='/LocationGalleryFacility425368SSQQPPkkii' exact component={LocationGalleryPageFacility} />
                  <Route path='/LocationGalleryPageEntrance425368SSQQPPkkii' exact component={LocationGalleryPageEntrance} />
                  <Route path='/FactionGalleryPageEntrance425368SSQQPPkkii' exact component={FactionGalleryPageEntrance} />
                  <Route path='/FactionGalleryCrawdaddy425368ppggrrll' exact component={FactionGalleryPageCrawdaddy} />
                  <Route path='/FactionGalleryBelleRiver425368ppggrrll' exact component={FactionGalleryPageBelleRiver} />
                  <Route path='/FactionGalleryConway425368ppggrrll' exact component={FactionGalleryPageConway} />
                  <Route path='/FactionGalleryShield425368ppggrrll' exact component={FactionGalleryPageShield} />
                  <Route path='/FactionGalleryBlade425368ppggrrll' exact component={FactionGalleryPageBlade} />
                  <Route path='/FactionGalleryOutlanders425368ppggrrll' exact component={FactionGalleryPageOutlanders} />
                  <Route path='/FactionGalleryRelic425368ppggrrll' exact component={FactionGalleryPageRelic} />
                  <Route path='/FactionGalleryBrigade425368ppggrrll' exact component={FactionGalleryPageBrigade} />
                  <Route path='/FactionGalleryVendetta425368ppggrrll' exact component={FactionGalleryPageVendetta} />
                  <Route path='/FactionGalleryPortstown425368ppggrrll' exact component={FactionGalleryPagePortstown} />
                  <Route path='/inputPage425368SSQQPPTTYY' exact component={StoryInputPage} />
                  <Route path='/MapPage425368LLPPYYTTqop' exact component={MapDisplay} />
                  <Route path='/' exact component={NewView} />
                  <Route path='/News' exact component={NewsDisplay} />
              </Switch>
          </Router>
      </>
  );
}

export default App;
